import { PREFIX_API } from 'constants/index';
import Instance from '../app.v2/config/instance';
import {
  GetExportTypeRequestParamDTO,
  GetExportTypeResponseDTO,
  ICreateExportType,
  IExportTypeDetail,
} from 'interfaces/ExportType';
export function getExportTypeRequest(
  params?: GetExportTypeRequestParamDTO,
): Promise<GetExportTypeResponseDTO> {
  return Instance.get(`/${PREFIX_API.EXPORTTYPE}`, {
    params: {
      ...params,
      where: JSON.stringify(params?.where || {}),
      order: JSON.stringify(params?.order || []),
    },
  });
}
export function getDetailExportType(
  ExportTypeId: string,
): Promise<IExportTypeDetail> {
  return Instance.get(`/${PREFIX_API.EXPORTTYPE}/${ExportTypeId}`);
}
export function createExportTypeRequest(
  ExportType: ICreateExportType,
): Promise<GetExportTypeResponseDTO> {
  return Instance.post(`/${PREFIX_API.EXPORTTYPE}`, ExportType);
}
export function updateExportTypeRequest(
  ExportType: ICreateExportType,
): Promise<GetExportTypeResponseDTO> {
  const payload: any = { ...ExportType };
  const id = ExportType.id;
  delete payload.id;
  return Instance.put(`/${PREFIX_API.EXPORTTYPE}/${id}`, payload);
}
export function getExportTypeRequestPaginationConfig(): Promise<GetExportTypeResponseDTO> {
  return Instance.get(`/${PREFIX_API.EXPORTTYPE}/pagination-config`);
}
