import { Col, Row } from 'antd';
import { SvgIcon } from 'app/components/SvgIcon';
import styles from './index.module.scss';

type Props = {
  vasp: any;
  folder: any;
};

interface ITextInfor {
  label: any;
  text: any;
  span?: number;
  dataTestId?: string;
}

const BeneficiaryInformation = (props: Props) => {
  const formatText = (text: string) => {
    let result = '-';

    if (text) {
      result = text.toString().toLowerCase();
      result = result.charAt(0).toUpperCase() + result.slice(1);
    }
    return result;
  };

  const TextInfor = ({ span = 12, label, text, dataTestId }: ITextInfor) => {
    return (
      <Col span={span}>
        <div className={styles.content__item}>
          <p className={styles.content__label}>{label}</p>
          <p className={styles.content__text} data-testid={dataTestId}>
            {text}
          </p>
        </div>
      </Col>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.info}>
          <div className={styles.icon}>
            <SvgIcon name="info-red" width={16} height={16} />
          </div>
          <div className={styles.title}>Beneficiary Information</div>
        </div>
      </div>
      <Row style={{ marginBottom: 20 }}>
        <TextInfor
          label="VASP"
          dataTestId="vasp"
          text={`${formatText(props.vasp?.name || '')}`}
        />
        <TextInfor
          label="Name"
          dataTestId="name"
          text={formatText(props?.folder?.businessInfo?.businessName || '')}
        />
      </Row>
      <Row>
        <TextInfor
          label="Registered business address"
          dataTestId="registered-business-address"
          text={formatText(props?.folder?.businessInfo?.businessAddress || '')}
        />
      </Row>
    </div>
  );
};

export default BeneficiaryInformation;
