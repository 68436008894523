import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  getTravelRuleVASPRequest,
  getDetailTravelRuleVASP,
  createTravelRuleVASPRequest,
  updateTravelRuleVASPRequest,
  getTravelRuleVASPRequestPaginationConfig,
} from 'services/TravelRuleVASP.service';
import { TravelRuleVASPActions } from 'store/slices/TravelRuleVASP/TravelRuleVASP.slice';
import { handleShowMsgErr } from 'utils/common';
import { GetTravelRuleVASPRequestParamDTO, ICreateTravelRuleVASP } from 'interfaces/TravelRuleVASP';
const handleGetTravelRuleVASPRequest = function* ({
  payload,
}: PayloadAction<GetTravelRuleVASPRequestParamDTO>) {
  try {
    const res = yield call(getTravelRuleVASPRequest, payload);
    const params = yield select(state => state.TravelRuleVASP.params) as any;
    const listTravelRuleVASP = yield select(
      state => state.TravelRuleVASP?.TravelRuleVASP?.data?.listTravelRuleVASP,
    ) as any;
    if (res) {
      yield put(
        TravelRuleVASPActions.setCanLoadMore(params.offset + params.limit < res.total),
      );
      if (params.offset > 0) {
        const newList: any = [...(listTravelRuleVASP || []), ...res.list];
        yield put(TravelRuleVASPActions.appendTravelRuleVASP(newList));
      } else {
        yield put(
          TravelRuleVASPActions.getTravelRuleVASPSuccess({
            listTravelRuleVASP: res.list,
            total: res.total,
          }),
        );
      }
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(TravelRuleVASPActions.getTravelRuleVASPFailed());
  }
};
const handleGetDetailTravelRuleVASPRequest = function* ({
  payload,
}: PayloadAction<string>) {
  try {
    const res = yield call(getDetailTravelRuleVASP, payload);
    if (res) {
      yield put(TravelRuleVASPActions.getDetailTravelRuleVASPSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(TravelRuleVASPActions.getDetailTravelRuleVASPFailed());
  }
};
const handleCreateTravelRuleVASPRequest = function* ({
  payload,
}: PayloadAction<ICreateTravelRuleVASP>) {
  try {
    const res = yield call(createTravelRuleVASPRequest, payload);
    if (res) {
      yield put(TravelRuleVASPActions.createTravelRuleVASPSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(TravelRuleVASPActions.createTravelRuleVASPFailed());
  }
};
const handleUpdateTravelRuleVASPRequest = function* ({
  payload,
}: PayloadAction<ICreateTravelRuleVASP>) {
  try {
    const res = yield call(updateTravelRuleVASPRequest, payload);
    if (res) {
      yield put(TravelRuleVASPActions.updateTravelRuleVASPSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(TravelRuleVASPActions.updateTravelRuleVASPFailed());
  }
};
const handleGetTravelRuleVASPPaginationConfigRequest = function* ({
  payload,
}: PayloadAction<GetTravelRuleVASPRequestParamDTO>) {
  try {
    const res = yield call(getTravelRuleVASPRequestPaginationConfig);
    if (res) {
      yield put(TravelRuleVASPActions.getTravelRuleVASPPaginationConfigSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(TravelRuleVASPActions.getTravelRuleVASPPaginationConfigFailed());
  }
};
const TravelRuleVASPSaga = function* () {
  yield takeLatest(TravelRuleVASPActions.getTravelRuleVASPRequest.type, handleGetTravelRuleVASPRequest);
  yield takeLatest(TravelRuleVASPActions.createTravelRuleVASPRequest.type, handleCreateTravelRuleVASPRequest);
  yield takeLatest(TravelRuleVASPActions.updateTravelRuleVASPRequest.type, handleUpdateTravelRuleVASPRequest);
  yield takeLatest(
    TravelRuleVASPActions.getTravelRuleVASPPaginationConfigRequest.type,
    handleGetTravelRuleVASPPaginationConfigRequest,
  );
  yield takeLatest(
    TravelRuleVASPActions.getDetailTravelRuleVASPRequest.type,
    handleGetDetailTravelRuleVASPRequest,
  );
};
export default TravelRuleVASPSaga;
