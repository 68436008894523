import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  getBatchElvenTxnRAKOnChainRequest,
  getDetailBatchElvenTxnRAKOnChain,
  createBatchElvenTxnRAKOnChainRequest,
  updateBatchElvenTxnRAKOnChainRequest,
  getBatchElvenTxnRAKOnChainRequestPaginationConfig,
} from 'services/BatchElvenTxnRAKOnChain.service';
import { BatchElvenTxnRAKOnChainActions } from 'store/slices/BatchElvenTxnRAKOnChain/BatchElvenTxnRAKOnChain.slice';
import { handleShowMsgErr } from 'utils/common';
import { GetBatchElvenTxnRAKOnChainRequestParamDTO, ICreateBatchElvenTxnRAKOnChain } from 'interfaces/BatchElvenTxnRAKOnChain';
const handleGetBatchElvenTxnRAKOnChainRequest = function* ({
  payload,
}: PayloadAction<GetBatchElvenTxnRAKOnChainRequestParamDTO>) {
  try {
    const res = yield call(getBatchElvenTxnRAKOnChainRequest, payload);
    const params = yield select(state => state.BatchElvenTxnRAKOnChain.params) as any;
    const listBatchElvenTxnRAKOnChain = yield select(
      state => state.BatchElvenTxnRAKOnChain?.BatchElvenTxnRAKOnChain?.data?.listBatchElvenTxnRAKOnChain,
    ) as any;
    if (res) {
      yield put(
        BatchElvenTxnRAKOnChainActions.setCanLoadMore(params.offset + params.limit < res.total),
      );
      if (params.offset > 0) {
        const newList: any = [...(listBatchElvenTxnRAKOnChain || []), ...res.list];
        yield put(BatchElvenTxnRAKOnChainActions.appendBatchElvenTxnRAKOnChain(newList));
      } else {
        yield put(
          BatchElvenTxnRAKOnChainActions.getBatchElvenTxnRAKOnChainSuccess({
            listBatchElvenTxnRAKOnChain: res.list,
            total: res.total,
          }),
        );
      }
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(BatchElvenTxnRAKOnChainActions.getBatchElvenTxnRAKOnChainFailed());
  }
};
const handleGetDetailBatchElvenTxnRAKOnChainRequest = function* ({
  payload,
}: PayloadAction<string>) {
  try {
    const res = yield call(getDetailBatchElvenTxnRAKOnChain, payload);
    if (res) {
      yield put(BatchElvenTxnRAKOnChainActions.getDetailBatchElvenTxnRAKOnChainSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(BatchElvenTxnRAKOnChainActions.getDetailBatchElvenTxnRAKOnChainFailed());
  }
};
const handleCreateBatchElvenTxnRAKOnChainRequest = function* ({
  payload,
}: PayloadAction<ICreateBatchElvenTxnRAKOnChain>) {
  try {
    const res = yield call(createBatchElvenTxnRAKOnChainRequest, payload);
    if (res) {
      yield put(BatchElvenTxnRAKOnChainActions.createBatchElvenTxnRAKOnChainSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(BatchElvenTxnRAKOnChainActions.createBatchElvenTxnRAKOnChainFailed());
  }
};
const handleUpdateBatchElvenTxnRAKOnChainRequest = function* ({
  payload,
}: PayloadAction<ICreateBatchElvenTxnRAKOnChain>) {
  try {
    const res = yield call(updateBatchElvenTxnRAKOnChainRequest, payload);
    if (res) {
      yield put(BatchElvenTxnRAKOnChainActions.updateBatchElvenTxnRAKOnChainSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(BatchElvenTxnRAKOnChainActions.updateBatchElvenTxnRAKOnChainFailed());
  }
};
const handleGetBatchElvenTxnRAKOnChainPaginationConfigRequest = function* ({
  payload,
}: PayloadAction<GetBatchElvenTxnRAKOnChainRequestParamDTO>) {
  try {
    const res = yield call(getBatchElvenTxnRAKOnChainRequestPaginationConfig);
    if (res) {
      yield put(BatchElvenTxnRAKOnChainActions.getBatchElvenTxnRAKOnChainPaginationConfigSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(BatchElvenTxnRAKOnChainActions.getBatchElvenTxnRAKOnChainPaginationConfigFailed());
  }
};
const BatchElvenTxnRAKOnChainSaga = function* () {
  yield takeLatest(BatchElvenTxnRAKOnChainActions.getBatchElvenTxnRAKOnChainRequest.type, handleGetBatchElvenTxnRAKOnChainRequest);
  yield takeLatest(BatchElvenTxnRAKOnChainActions.createBatchElvenTxnRAKOnChainRequest.type, handleCreateBatchElvenTxnRAKOnChainRequest);
  yield takeLatest(BatchElvenTxnRAKOnChainActions.updateBatchElvenTxnRAKOnChainRequest.type, handleUpdateBatchElvenTxnRAKOnChainRequest);
  yield takeLatest(
    BatchElvenTxnRAKOnChainActions.getBatchElvenTxnRAKOnChainPaginationConfigRequest.type,
    handleGetBatchElvenTxnRAKOnChainPaginationConfigRequest,
  );
  yield takeLatest(
    BatchElvenTxnRAKOnChainActions.getDetailBatchElvenTxnRAKOnChainRequest.type,
    handleGetDetailBatchElvenTxnRAKOnChainRequest,
  );
};
export default BatchElvenTxnRAKOnChainSaga;
