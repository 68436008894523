import { PREFIX_API } from 'constants/index';
import Instance from 'app.v2/config/instance';
import {
  GetBatchElvenTxnRAKOnChainRequestParamDTO,
  GetBatchElvenTxnRAKOnChainResponseDTO,
  ICreateBatchElvenTxnRAKOnChain,
  IBatchElvenTxnRAKOnChainDetail,
} from 'interfaces/BatchElvenTxnRAKOnChain';
export function getBatchElvenTxnRAKOnChainRequest(
  params?: GetBatchElvenTxnRAKOnChainRequestParamDTO,
): Promise<GetBatchElvenTxnRAKOnChainResponseDTO> {
  return Instance.get(`/${PREFIX_API.BATCHELVENTXNRAKONCHAIN}`, {
    params: {
      ...params,
      where: JSON.stringify(params?.where || {}),
      order: JSON.stringify(params?.order || []),
    },
  });
}
export function getDetailBatchElvenTxnRAKOnChain(
  BatchElvenTxnRAKOnChainId: string,
): Promise<IBatchElvenTxnRAKOnChainDetail> {
  return Instance.get(`/${PREFIX_API.BATCHELVENTXNRAKONCHAIN}/${ BatchElvenTxnRAKOnChainId }`);
}
export function createBatchElvenTxnRAKOnChainRequest(
  BatchElvenTxnRAKOnChain: ICreateBatchElvenTxnRAKOnChain,
): Promise<GetBatchElvenTxnRAKOnChainResponseDTO> {
  return Instance.post(`/${PREFIX_API.BATCHELVENTXNRAKONCHAIN}`, BatchElvenTxnRAKOnChain);
}
export function updateBatchElvenTxnRAKOnChainRequest(
  BatchElvenTxnRAKOnChain: ICreateBatchElvenTxnRAKOnChain,
): Promise<GetBatchElvenTxnRAKOnChainResponseDTO> {
  const payload: any = { ...BatchElvenTxnRAKOnChain };
  const id = BatchElvenTxnRAKOnChain.id;
  delete payload.id;
  return Instance.put(`/${PREFIX_API.BATCHELVENTXNRAKONCHAIN}/${id}`, payload);
}
export function getBatchElvenTxnRAKOnChainRequestPaginationConfig(): Promise<GetBatchElvenTxnRAKOnChainResponseDTO> {
  return Instance.get(`/${PREFIX_API.BATCHELVENTXNRAKONCHAIN}/pagination-config`);
}
