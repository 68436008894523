import { LoadingOutlined } from '@ant-design/icons';
import { Button, Drawer, Form, Input, Row } from 'antd';
import { SvgIcon } from 'app/components/common/SvgIcon';
import { IReportTypeDetail } from 'interfaces/ReportType';
import { RequestStatus } from 'interfaces/request';
import { startCase, upperFirst } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  ReportTypeActions,
  selectReportTypeDetail,
} from 'store/slices/ReportType/ReportType.slice';
import styled from 'styled-components/macro';
import { getTextAddress } from 'utils/helpers';
import styles from './ReportTypeDetail.module.scss';
import { Rule } from 'antd/lib/form';
import RButton from '../Button';
const EntityFields = [
  { key: 'id', required: false, disabled: true, canInsert: true },
  // { key: 'id' },
  { key: 'name' },
  { key: 'description' },
  // { key: 'isDeleted' },
  // { key: 'status' },
  // { key: 'createdBy' },
  // { key: 'updatedBy' },
  // { key: 'createdAt' },
  // { key: 'updatedAt' },
  // { key: 'customerId' },
];
const layoutEdit = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};
const tailLayoutEdit = {
  wrapperCol: { offset: 8, span: 16 },
};
export const ReportTypeEditChildren = ({
  item,
  isMaskReportType,
  id,
  onClose,
}) => {
  const isCreated = id === undefined;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const ReportTypeDetail = useAppSelector(selectReportTypeDetail);
  const [entityFields, setEntityFields] = useState(EntityFields);
  useEffect(() => {
    if (isCreated) {
      setEntityFields(EntityFields);
    }
  }, [isCreated]);
  useEffect(() => {
    if (!isCreated) {
      form.setFieldsValue(item);
    }
  }, [form, item]);
  const onFinish = (values: any) => {
    if (isCreated) {
      dispatch(ReportTypeActions.createReportTypeRequest(values));
    } else {
      dispatch(ReportTypeActions.updateReportTypeRequest(values));
    }
  };
  //TODO: refactor later
  useEffect(() => {
    if (isCreated && ReportTypeDetail?.data?.['id'] !== undefined) {
      dispatch(ReportTypeActions.resetReportTypeParams());
      onReset();
      onClose();
    }
    if (!!id && ReportTypeDetail?.data?.['affected'] !== undefined) {
      dispatch(ReportTypeActions.resetReportTypeParams());
      onClose();
    }
  }, [dispatch, ReportTypeDetail.data]);
  const onReset = () => {
    form.resetFields();
  };
  return (
    <Form {...layoutEdit} form={form} name="control-hooks" onFinish={onFinish}>
      {entityFields.map(
        ({ key, required = true, disabled = false, canInsert = true }) => {
          return (
            <Form.Item
              name={key}
              label={upperFirst(key)}
              rules={[{ required: required }] as Rule[]}
            >
              <Input
                required={isCreated && canInsert ? true : required}
                disabled={isCreated && canInsert ? false : disabled}
              />
            </Form.Item>
          );
        },
      )}
      <Form.Item {...tailLayoutEdit}>
        <RButton
          htmlType="submit"
          buttonType="submit"
          content="Submit"
          width={100}
          height={32}
          style={{ marginLeft: '20px' }}
        />
        <RButton
          htmlType="button"
          buttonType="cancel"
          content="Reset"
          width={100}
          height={32}
          onClick={onReset}
          style={{ marginLeft: '10px' }}
        />
      </Form.Item>
    </Form>
  );
};
export const ReportTypeDetailChildren = ({ item, isMaskReportType }) => {
  const { id, name, symbol } = item || {};
  return (
    <div>
      <Section>
        <div className="title">Detail</div>
        <div className="line"></div>
      </Section>
      <SectionDataContainer>
        {EntityFields.map(({ key }) => {
          return (
            <SectionName key={key}>
              <div className="title">{upperFirst(key)}</div>
              <div className="label" data-testid="category">
                {item?.[key] || '--'}
              </div>
            </SectionName>
          );
        })}
      </SectionDataContainer>
    </div>
  );
};
const MetadataDetailChildren = ({ item, isMaskReportType }) => {
  return (
    <MetadataContainer isMaskReportType={isMaskReportType}>
      <Section>
        <div className="title">Metadata</div>
        <div className="line"></div>
      </Section>
      {item &&
        Object.keys(item).map(key => {
          let value;
          if (typeof item[key] === 'string' || typeof item[key] === 'number') {
            value = item[key];
          } else {
            value =
              key === 'address'
                ? getTextAddress(item[key])
                : JSON.stringify(item[key]);
          }
          return (
            <SectionDataContainer key={key}>
              <SectionName>
                <div className="title">{startCase(key)}</div>
                <div className="label">{value}</div>
              </SectionName>
            </SectionDataContainer>
          );
        })}
    </MetadataContainer>
  );
};
const getItems = (
  item?: IReportTypeDetail,
  isMaskReportType?: boolean,
  id?: string | number,
  onClose?: () => void,
) => [
  ...(id
    ? [
        {
          key: '1',
          label: `Detail info`,
          children: (
            <ReportTypeDetailChildren
              item={item}
              isMaskReportType={isMaskReportType}
            />
          ),
        },
        // {
        //   key: '2',
        //   label: `Metadata`,
        //   children: (
        //     <MetadataDetailChildren
        //       item={item?.metaData}
        //       isMaskReportType={isMaskReportType}
        //     />
        //   ),
        // },
      ]
    : []),
  {
    key: '3',
    label: `Upsert`,
    children: (
      <ReportTypeEditChildren
        item={item}
        isMaskReportType={isMaskReportType}
        id={id}
        onClose={onClose}
      />
    ),
  },
];
const TabBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;
`;
const TabBar = styled.div<{ isActive: boolean }>`
  width: 292px;
  height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${({ isActive }) => (isActive ? '#25397C' : '#2C303C')};
  background-color: ${({ isActive }) => (isActive ? '#E5EDFC' : '#FFFFFF')};
  border: 1px solid ${({ isActive }) => (isActive ? '#CEDDFB' : '#D5D7DF')};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 24px;
  cursor: pointer;
`;
const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 6px 8px;
  .LogID {
    font-weight: 600;
    font-size: 12px;x
    color: #959aaa;
  }
  .icon {
    cursor: pointer;
  }
`;
const Section = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  .title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #2c303c;
  }
  .line {
    height: 1px;
    flex: 1;
    background: #ebecf0;
    margin-left: 20px;
  }
`;
const MetadataContainer = styled.div<{ isMaskReportType: boolean }>`
  filter: ${({ isMaskReportType }) =>
    isMaskReportType ? 'blur(6px)' : 'none'};
`;
const SectionDataContainer = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;
const SectionName = styled.div<{ isName?: boolean }>`
  flex: 1;
  .title {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #64697b;
    margin-bottom: 6px;
  }
  .label {
    font-weight: ${({ isName }) => (isName ? 600 : 400)};
    font-size: 14px;
    line-height: 20px;
    color: ${({ isName }) => (isName ? '#2E90FA' : '#2C303C')};
  }
`;
interface Props {
  open: boolean;
  onClose: () => void;
  itemSelected: any;
}
const ReportTypeDetail: React.FunctionComponent<Props> = ({
  open,
  onClose,
  itemSelected,
}) => {
  const { id } = itemSelected || {};
  const [mode, setMode] = useState(0);
  const dispatch = useAppDispatch();
  const ReportTypeDetail = useAppSelector(selectReportTypeDetail);
  const [isMaskReportType, setIsMaskReportType] = useState(true);
  const isLoadingDetail = useMemo(() => {
    return ReportTypeDetail.status === RequestStatus.Loading;
  }, [ReportTypeDetail.status]);
  useEffect(() => {
    if (!open) {
      setIsMaskReportType(true);
    }
  }, [open]);
  const items = getItems(ReportTypeDetail.data, isMaskReportType, id, () => {
    onClose?.();
  });
  useEffect(() => {
    if (id) {
      dispatch(ReportTypeActions.getDetailReportTypeRequest(id));
    }
  }, [id, ReportTypeDetail?.data?.id]);
  useEffect(() => {
    setMode(0);
  }, [open]);
  const Spinner = (
    <LoadingOutlined style={{ fontSize: 64, color: '#D63A95' }} />
  );
  const content =
    isLoadingDetail || !ReportTypeDetail.data ? (
      <div className={styles.loading}>{Spinner}</div>
    ) : (
      <>
        <TabBarContainer>
          {items.map(({ key, label }, index) => (
            <TabBar
              key={key}
              isActive={index === mode}
              onClick={() => setMode(index)}
            >
              {label}
            </TabBar>
          ))}
        </TabBarContainer>
        {items[mode].children}
      </>
    );
  return (
    <Drawer
      width="664px"
      placement="right"
      onClose={onClose}
      forceRender
      open={open}
      keyboard={false}
      title={
        <TitleContainer>
          <Row style={{ gap: 8, alignItems: 'center' }}>
            <div className="LogID" data-testid="log-ID">
              {`Log ID: ${id}`}
            </div>
            <SvgIcon
              name={isMaskReportType ? 'black-eye' : 'black-eye-off'}
              onClick={() => setIsMaskReportType(!isMaskReportType)}
              width={16}
              height={isMaskReportType ? 12 : 16}
              style={{ cursor: 'pointer', marginRight: '20px' }}
            />
          </Row>
          <div className="icon" onClick={onClose}>
            <SvgIcon name="close" width={16} height={16} />
          </div>
        </TitleContainer>
      }
      closable={false}
      bodyStyle={{ padding: '16px 32px 32px' }}
      // className={styles.drawerBilling}
    >
      {content}
    </Drawer>
  );
};
export default ReportTypeDetail;
