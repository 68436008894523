import AccessControl from 'app/pages/AccessControl';
import AuditLog from 'app/pages/AuditLog/AuditLog.page';
import WalletInfo from 'app/pages/WalletInfo/WalletInfo.page';
import { PathList } from 'constants/index';
import Currency from 'app/pages/Currency/Currency.page';
import CurrencyConvert from 'app/pages/CurrencyConvert/CurrencyConvert.page';
import WcWeb3Connect from 'app/pages/WcWeb3Connect/WcWeb3Connect.page';
import WcAdapter from 'app/pages/WcAdapter/WcAdapter.page';
import WcAppAdapter from 'app/pages/WcAppAdapter/WcAppAdapter.page';
import WcRequestWeb3Connect from 'app/pages/WcRequestWeb3Connect/WcRequestWeb3Connect.page';
import WcAppEntity from 'app/pages/WcAppEntity/WcAppEntity.page';
import VaultWc from 'app/pages/VaultWc/VaultWc.page';
import CusSubscribe from 'app/pages/CusSubribe/CusSubscribe.page';
import WcApplication from 'app/pages/WcApplication/WcApplication.page';
import CfgSourceOfFund from 'app/pages/Cfg_sourceOfFund/Cfg_sourceOfFund.page';
import CfgTypeOfCompany from 'app/pages/Cfg_typeOfCompany/Cfg_typeOfCompany.page';
import CfgCountry from 'app/pages/Cfg_country/Cfg_country.page';
import BatchElvenTxnRAKFB from 'app/pages/BatchElvenTxnRAKFB/BatchElvenTxnRAKFB.page';
import BatchElvenTxnRAKOnChain from 'app/pages/BatchElvenTxnRAKOnChain/BatchElvenTxnRAKOnChain.page';
import ReportType from 'app/pages/ReportType/ReportType.page';
import ExportType from 'app/pages/ExportType/ExportType.page';
import TravelRuleVASP from 'app/pages/TravelRuleVASP/TravelRuleVASP.page';
import TravelRuleJurisdictions from 'app/pages/TravelRuleJurisdictions/TravelRuleJurisdictions.page';

const userRoute = [
  {
    path: '/audit-log',
    element: <AuditLog />,
  },
  {
    path: '/access-control',
    element: <AccessControl />,
  },
  {
    path: PathList.walletInfo,
    element: <WalletInfo />,
  },
  {
    path: PathList.currency,
    element: <Currency />,
  },

  {
    path: PathList.wcWeb3Connect,
    element: <WcWeb3Connect />,
  },
  {
    path: PathList.wcAdapter,
    element: <WcAdapter />,
  },
  {
    path: PathList.wcAppAdapter,
    element: <WcAppAdapter />,
  },
  {
    path: PathList.wcRequestWeb3Connect,
    element: <WcRequestWeb3Connect />,
  },
  {
    path: PathList.wcApplication,
    element: <WcApplication />,
  },
  {
    path: PathList.wcAppEntity,
    element: <WcAppEntity />,
  },
  {
    path: PathList.vaultWc,
    element: <VaultWc />,
  },
  {
    path: PathList.cusSubscribe,
    element: <CusSubscribe />,
  },

  {
    path: PathList.currencyConvert,
    element: <CurrencyConvert />,
  },
  {
    path: PathList.cfg_sourceOfFund,
    element: <CfgSourceOfFund />,
  },
  {
    path: PathList.cfg_typeOfCompany,
    element: <CfgTypeOfCompany />,
  },
  {
    path: PathList.cfg_country,
    element: <CfgCountry />,
  },
  {
    path: PathList.BatchElvenTxnRAKFB,
    element: <BatchElvenTxnRAKFB />,
  },
  {
    path: PathList.BatchElvenTxnRAKOnChain,
    element: <BatchElvenTxnRAKOnChain />,
  },
  {
    path: PathList.ReportType,
    element: <ReportType />,
  },
  {
    path: PathList.ExportType,
    element: <ExportType />,
  },
  {
    path: PathList.TravelRuleVASP,
    element: <TravelRuleVASP />,
  },
  {
    path: PathList.TravelRuleJurisdictions,
    element: <TravelRuleJurisdictions />,
  },
];

export default userRoute;
