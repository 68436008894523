import { LoadingOutlined } from '@ant-design/icons';
import { Drawer, Row } from 'antd';
import { SvgIcon } from 'app/components/SvgIcon';
import BasicButton from 'app/components/common/BasicButton';
import clsx from 'clsx';
import { FORMAT_DATE, STATUS_REQUEST } from 'constants/index';
import {
  TransactionStatus,
  TransactionType,
  TransactionTypeSource,
  USER_ROLES,
  VAULT_POLICY_TYPE,
} from 'enum/common';
import { RequestType } from 'enum/pendingRequest';
import {
  SourceType,
  StatusUserApprovalLogs,
  SubStatus,
  TransactionStatusFireBlocks,
  TransactionStatusResponse,
} from 'enum/transaction';
import avatar from 'images/avatar.svg';
import folderIcon from 'images/folder.svg';
import { RequestStatus } from 'interfaces/request';
import {
  SyncDataResponse,
  TransactionRecord,
  UserApproval,
  UserApprovalLog,
} from 'interfaces/transactions';
import { filter, find, lowerCase } from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectRoles, selectUserProfile } from 'store/slices/auth';
import { commentActions, selectComments } from 'store/slices/comment';
import {
  selectRejectUnfreezeTxnData,
  selectReviewChecklist,
  selectReviewerByTxnIdData,
  selectTransactionDetail,
  selectTransactionTier,
  transactionActions,
} from 'store/slices/transaction';
import { checkRoles } from 'utils/common';
import { getTier } from 'utils/transaction.utils';
import ApprovalLog from './ApprovalLog';
import BoxComment from './BoxComment';
import Checklist, { IChecklist } from './Checklist';
import {
  ApprovalTransaction,
  CheckApprovalLog,
  ReviewKYTInfomation,
  ReviewTransactionChecklist,
  SignTransaction,
  VerifyCustomer,
  VerifyCustomerVDO,
} from './Checklist/CheckListStatusCard';
import KYTInformation from './KYTInformation';
import Signer from './Signer';
import TransactionInformation from './TransactionInformation';
import AdvancedVaultPolicy from './VaultPolicy';
import MarkAsReviewed from './components/MarkAsReviewed';
import RejectTransaction from './components/RejectTransaction';
import UnfreezeTransaction from './components/UnfreezeTransaction';
import styles from './index.module.scss';

import ToastMessage from 'app/components/common/ToastMessage';
import { toast } from 'react-toastify';
import {
  selectparamsTransactionList,
  transactionsActions,
} from 'store/slices/transactions';
import InitiatorAndApprovers from './InitiatorAndApprovers';
import AssetInformation from './AssetInformation';
import FolderInformation from './FolderInformation';
import WalletVerification from './WalletVerification';
import { getVaspDetail } from 'services/transaction';
import { getCustomerById } from 'services/customerProfile';

type Props = {
  open: boolean;
  setOpen: (v: boolean) => void;
  detail?: TransactionRecord;
  txnId: string;
};

const DetailTransactionChecklist = ({
  executeScroll,
  reviewer,
  checklist,
  checkedList,
  setCheckedList,
  isSentRequest,
  type,
}: {
  executeScroll: (value: string) => void;
  reviewer: any;
  checklist: {
    operationsChecklist: IChecklist[];
    complianceChecklist: IChecklist[];
  };
  checkedList: string[];
  setCheckedList: React.Dispatch<React.SetStateAction<string[]>>;
  isSentRequest: boolean;
  type: string;
}) => {
  if (
    reviewer ||
    (!checklist.operationsChecklist?.length &&
      !checklist.complianceChecklist?.length) ||
    isSentRequest
  )
    return null;
  let operationsChecklist: IChecklist[] = checklist.operationsChecklist;
  let complianceChecklist: IChecklist[] = checklist.complianceChecklist;

  return (
    <div className={styles.checklist}>
      {type === TransactionTypeSource.INCOMING && (
        <Checklist
          executeScroll={executeScroll}
          checklist={operationsChecklist}
          checkedList={checkedList}
          setCheckedList={setCheckedList}
        />
      )}
      {(type === TransactionTypeSource.OUTGOING ||
        type === TransactionTypeSource.REBALANCING) && (
        <>
          <Checklist
            executeScroll={executeScroll}
            checklist={operationsChecklist}
            checkedList={checkedList}
            setCheckedList={setCheckedList}
            checklistTitle="Operation"
          />
          <Checklist
            executeScroll={executeScroll}
            checklist={complianceChecklist}
            checkedList={checkedList}
            setCheckedList={setCheckedList}
            checklistTitle="Compliance"
          />
        </>
      )}
    </div>
  );
};

export const isStakingTransaction = (transactionType: any) => {
  return (
    transactionType === TransactionType.STAKING ||
    transactionType === TransactionType.UNSTAKING ||
    transactionType === TransactionType.CHANGE_POOL ||
    transactionType === TransactionType.CLAIM_REWARD
  );
};

const DetailTransaction = ({ open, setOpen, detail, txnId }: Props) => {
  const dispatch = useAppDispatch();
  const transaction = useSelector(selectTransactionDetail);
  const transactionDetail = useMemo(
    () => transaction?.data ?? {},
    [transaction],
  );
  const params = useAppSelector(selectparamsTransactionList);
  const reviewerData = useAppSelector(selectReviewerByTxnIdData)?.data ?? {};
  const reviewChecklist = useAppSelector(selectReviewChecklist);
  const vaultPolicy = transactionDetail?.vaultPolicy?.users || [];
  const approvalLogs = transactionDetail?.approvalLogs;
  const comments = useAppSelector(selectComments) || [];
  const roles = useAppSelector(selectRoles);
  const accountMe = useAppSelector(selectUserProfile);
  const rejectUnfreezeTransaction = useAppSelector(selectRejectUnfreezeTxnData);
  const txnDetailRef = useRef<any>(null);
  const kytInfoRef = useRef<any>(null);
  const approvalLogRef = useRef<any>(null);
  const customerRef = useRef<any>(null);
  const initiatorRef = useRef<any>(null);
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [isOpenRejectRequest, setIsOpenRejectRequest] =
    useState<boolean>(false);
  const [isOpenApproveRequest, setIsOpenApproveRequest] =
    useState<boolean>(false);
  const [isOpenMarkAsReviewed, setIsOpenMarkAsReviewed] =
    useState<boolean>(false);
  const [isOperationRole, setIsOperationRole] = useState<boolean>(false);
  useState<boolean>(false);
  const [isMaskedTransaction, setisMaskedTransaction] = useState<boolean>(true);
  const [isAllowedToMarkAsReview, setIsAllowedToMarkAsReview] =
    useState<boolean>(false);
  const [reviewer, setReviewer] = useState<any>();
  const transactionType = detail?.type;
  const transactionTier = useAppSelector(selectTransactionTier);
  const [syncData, setSyncData] = useState<Partial<SyncDataResponse>>();
  const [vasp, setVasp] = useState<any>();
  const [customer, setCustomer] = useState<any>();
  const [isSourceWhiteList, setIsSourceWhiteList] = useState(false);
  const [isOpenWhiteListModal, setIsOpenWhiteListModal] = useState(false);
  const [isVaspLoading, setIsVaspLoading] = useState(false);
  const [isCustomerLoading, setIsCustomerLoading] = useState(false);

  const folder = useMemo(
    () =>
      transactionDetail?.[isSourceWhiteList ? 'sourceData' : 'destinationData'],
    [transactionDetail, isSourceWhiteList],
  );

  useEffect(() => {
    if (isOpenWhiteListModal) {
      onGetTransactionVaspDetail(folder?.address?.vaspId);
    }
  }, [folder?.address?.vaspId, isOpenWhiteListModal]);

  useEffect(() => {
    if (isOpenWhiteListModal) {
      onGetTransactionCustomerDetail(transactionDetail?.customerId);
    }
  }, [transactionDetail?.customerId, isOpenWhiteListModal]);

  useEffect(() => {
    if (syncData?.affected && detail) {
      dispatch(
        transactionActions.getTransactionDetailByTxnIdRequest({
          txId: detail.id,
        }),
      );
      dispatch(transactionsActions.resetParamsTransaction());
      dispatch(transactionsActions.getTransactionListRequest(params));
      toast(
        <ToastMessage
          type="info"
          message="The transaction detail has been updated."
        />,
      );
    } else if (syncData?.expiredAt) {
      toast(
        <ToastMessage
          type="error"
          message="The transaction details cannot be updated. Please try again."
        />,
      );
    }
  }, [syncData]);

  const isStaking = useMemo(() => {
    return isStakingTransaction(transactionType);
  }, [transactionType]);

  const tier = useMemo(() => {
    if (
      transactionTier?.status !== RequestStatus.Success ||
      !transactionTier?.data?.length ||
      transactionDetail.type === 'INCOMING'
    ) {
      return null;
    }
    return (
      getTier(
        transactionDetail.workspaceid,
        transactionDetail.totalUSD,
        transactionTier.data,
      ) || null
    );
  }, [transactionDetail, transactionTier]);

  const handleStatusTransaction = (status, transaction) => {
    if (!status || !transaction) return <></>;
    if (
      (transaction?.fireblocksStatus ===
        TransactionStatusFireBlocks.PENDING_AUTHORIZATION &&
        lowerCase(status) === TransactionStatus.PROCESSING) ||
      (transaction?.type === TransactionTypeSource.INCOMING &&
        lowerCase(status) === TransactionStatus.FAILED) ||
      (transaction?.fireblocksStatus ===
        TransactionStatusFireBlocks.PENDING_AML_SCREENING &&
        transaction?.type === TransactionTypeSource.OUTGOING &&
        lowerCase(status) === TransactionStatus.PROCESSING)
    )
      return TransactionStatus.PENDING_REVIEW;
    return TransactionStatus[status];
  };

  const checklist = role => {
    return [
      {
        label: <ReviewTransactionChecklist />,
        value: 'transactionDetail',
        ref: txnDetailRef,
      },
      {
        label: (
          <ReviewKYTInfomation
            transactionStatus={handleStatusTransaction(
              transactionDetail?.status,
              transactionDetail,
            )}
            sourceType={transactionDetail?.vaultType}
          />
        ),
        value: 'reviewKYTInformation',
        ref: kytInfoRef,
      },
      {
        label: (
          <CheckApprovalLog
            transactionStatus={handleStatusTransaction(
              transactionDetail?.status,
              transactionDetail,
            )}
            transactionType={transactionDetail?.transactionType}
            tier={tier}
          />
        ),
        value: 'verifyApprovalLogWithQuorumRequirement',
        ref: approvalLogRef,
      },
      {
        label: <VerifyCustomer />,
        value: 'verifyCustomer',
        ref: customerRef,
      },
      {
        label: <VerifyCustomerVDO />,
        value: 'verifyCustomerIdentityFromLiveVideoCapture',
        ref: initiatorRef,
      },
      {
        label: <SignTransaction sourceType={transactionDetail?.vaultType} />,
        value: 'signTransactionOnFBColdWalletApp',
        ref: null,
      },
      {
        label: (
          <ApprovalTransaction
            sourceType={transactionDetail?.vaultType}
            role={role}
          />
        ),
        value: 'approveTransactionOnFBMobileApp',
        ref: null,
      },
    ];
  };

  let complianceChecklist: string[] = [];
  let operationsChecklist: string[] = [];
  if (isAllowedToMarkAsReview) {
    operationsChecklist = Object.keys(
      reviewChecklist?.data?.operation || {},
    ).filter(key => reviewChecklist?.data?.operation?.[key]);
    complianceChecklist = Object.keys(
      reviewChecklist?.data?.compliance || {},
    ).filter(key => reviewChecklist?.data?.compliance?.[key]);
  }

  const executeScroll = (value: string) => {
    const portion = checklist('').find(checkItem => checkItem.value === value);
    portion?.ref?.current?.scrollIntoView();
  };

  const onClose = () => {
    setOpen(false);
  };

  const onCloseWhiteListDetail = () => {
    setIsOpenWhiteListModal(false);
  };

  const onOpenWhiteListModal = (isSource = false) => {
    setIsSourceWhiteList(isSource);
    setIsOpenWhiteListModal(true);
  };

  const onGetTransactionVaspDetail = async vaspId => {
    if (vaspId) {
      setIsVaspLoading(true);
      const vasp = await getVaspDetail(vaspId);
      setVasp(vasp);
      setIsVaspLoading(false);
    }
  };

  const onGetTransactionCustomerDetail = async customerId => {
    if (customerId) {
      setIsCustomerLoading(true);
      const customer = await getCustomerById(customerId);
      setCustomer(customer);
      setIsCustomerLoading(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleCloseDrawer = () => {
    setOpen(false);
  };

  const userIntiator = useMemo(() => {
    return find(
      approvalLogs,
      item => item.status === StatusUserApprovalLogs.INITIATED,
    ) as UserApproval;
  }, [approvalLogs]);

  useEffect(() => {
    if (transactionTier?.status !== RequestStatus.Success) {
      dispatch(transactionActions.getTransactionTierRequest());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const usersApprover = useMemo(() => {
    return filter(
      approvalLogs,
      item =>
        !!item.status &&
        item.status !== StatusUserApprovalLogs.PENDING &&
        item.status !== StatusUserApprovalLogs.INITIATED &&
        // item.status !== StatusUserApprovalLogs.PROCESSING &&
        !(
          item?.userId === userIntiator?.userId &&
          item.status === StatusUserApprovalLogs.APPROVED
        ),
    ) as UserApprovalLog[];
  }, [approvalLogs]);

  const filteredChecklist = (requiredChecklist, role) => {
    const list = checklist(role);
    return list.filter(cl => {
      return requiredChecklist.find(key => key === cl.value);
    });
  };

  useEffect(() => {
    if (!detail?.id) return;
    dispatch(
      commentActions.getCommentsRequest({
        txnId: detail?.id,
      }),
    );
    dispatch(
      transactionActions.getTransactionDetailByTxnIdRequest({
        txId: detail?.id,
      }),
    );
    dispatch(
      transactionActions.getReviewerByTxnIdRequest({
        txId: detail?.id,
      }),
    );
    dispatch(
      transactionActions.getReviewChecklistByTxnIdRequest({
        txId: detail?.id,
      }),
    );
  }, [detail?.id]);

  useEffect(() => {
    if (reviewerData?.reviewers?.length > 0 && accountMe) {
      const reviewerAtFooter = reviewerData?.reviewers?.find(
        reviewer => reviewer.id === accountMe.id,
      );
      if (reviewerAtFooter) {
        const newReviewer = {
          createdAt: reviewerAtFooter.createdAt,
          reviewer: {
            avatar: reviewerAtFooter.avatar,
            name: reviewerAtFooter.name,
          },
          id: reviewerAtFooter.id,
        };
        setReviewer(newReviewer);
      } else {
        setReviewer(null);
      }
    } else if (
      transactionDetail?.requestId &&
      transactionDetail?.status === 'FAILED' &&
      transactionDetail?.type === 'INCOMING' &&
      transactionDetail?.pendingRequests?.length === 0
    ) {
      const reviewer = transactionDetail.approvalLogs?.find(
        approvalLog => approvalLog.status === 'INITIATED',
      );
      const newReviewer = {
        createdAt: reviewer?.createdAt,
        reviewer: {
          avatar: reviewer?.approverAvatar,
          name: reviewer?.approverName,
        },
        id: reviewer?.userId,
      };
      if (
        (isAllowedToMarkAsReview && //check case not show footer reject and unfreeze btn
          transactionDetail?.fireblocksStatus ===
            TransactionStatusFireBlocks.REJECTED &&
          transactionDetail?.requestStatus === STATUS_REQUEST.APPROVED) ||
        transactionDetail?.fireblocksStatus !==
          TransactionStatusFireBlocks.REJECTED
      ) {
        setReviewer(newReviewer);
      }
    } else {
      setReviewer(null);
    }
  }, [reviewerData, transactionDetail?.requestId]);

  const title = useMemo(() => {
    return (
      <div className={styles.title}>
        <div className={styles.content}>
          TXID: {detail?.transactionId}
          {'  '}
          <SvgIcon
            name={isMaskedTransaction ? 'black-eye' : 'black-eye-off'}
            onClick={() => setisMaskedTransaction(!isMaskedTransaction)}
            width={16}
            height={isMaskedTransaction ? 12 : 16}
            className={styles.eye_icon}
          />
        </div>

        <div className={styles.icon}>
          <SvgIcon
            name="close"
            width={16}
            height={16}
            onClick={handleCloseDrawer}
          />
        </div>
      </div>
    );
  }, [detail?.name, handleCloseDrawer]);

  const isLoadingDetail = useMemo(() => {
    return (
      !detail?.id ||
      transaction.status === RequestStatus.Loading ||
      reviewChecklist.status === RequestStatus.Loading
    );
  }, [detail?.id, transaction.status, reviewChecklist.status]);

  const Spinner = (
    <LoadingOutlined style={{ fontSize: 64, color: '#D63A95' }} />
  );

  const markAsReviewedFooter = useMemo(() => {
    let disabled: boolean = true;
    if (transactionDetail.vaultType === SourceType.COLD_WALLET) {
      disabled = !checkedList.some(
        checkedItem =>
          checkedItem === 'signTransactionOnFBColdWalletApp' ||
          checkedItem === 'approveTransactionOnFBMobileApp',
      );
    } else {
      disabled = !checkedList.some(
        checkedItem => checkedItem === 'approveTransactionOnFBMobileApp',
      );
    }
    return (
      <Row className={styles.transaction__footer}>
        <>
          <BasicButton
            disabled={disabled}
            title="Mark as reviewed"
            type="filled"
            className={styles.mark__reviewed}
            onClick={() => {
              setIsOpenMarkAsReviewed(true);
            }}
          />
        </>
      </Row>
    );
  }, [checkedList, complianceChecklist]);

  const reviewedFooter = (
    <Row className={styles.user__footer}>
      <Row className={clsx(styles.status, styles.status__approved)}>
        <SvgIcon
          name="approve-sign"
          width={14}
          height={14}
          style={{ marginTop: 0 }}
        />
        <span className={styles.status__text}>
          {transactionDetail?.status === 'FAILED' &&
          transactionDetail?.type === 'INCOMING'
            ? 'UNFROZEN'
            : 'REVIEWED'}
        </span>
      </Row>
      <span className={styles.created__by}>BY</span>
      <div className={styles.image}>
        <img
          src={reviewer?.reviewer?.avatar || avatar}
          alt=""
          data-testid="avatar"
        />
      </div>
      <span className={styles.created__name}>{reviewer?.reviewer?.name}</span>
      <span className={styles.created__time}>
        {moment(reviewer?.createdAt).format(FORMAT_DATE.DATE_DMY_hM)}{' '}
      </span>
    </Row>
  );

  const pendingFooter = useMemo(() => {
    let requestExisted = false;
    const pendingRequests = transactionDetail?.pendingRequests || [];
    if (pendingRequests.length) {
      const idx = pendingRequests.findIndex(item => {
        return item?.action === RequestType.UNFREEZE_TRANSACTION;
      });
      if (idx !== -1) {
        requestExisted = true;
      }
    }
    const disabled =
      operationsChecklist.length !== checkedList.length || requestExisted;

    if (requestExisted) return;
    return (
      <Row className={styles.transaction__footer} style={{ gap: 12 }}>
        <>
          <BasicButton
            title="Reject"
            type="outline"
            className={styles.reject__button}
            onClick={() => setIsOpenRejectRequest(true)}
            disabled={disabled}
          />
          <BasicButton
            title="Unfreeze"
            type="filled"
            onClick={() => setIsOpenApproveRequest(true)}
            disabled={disabled}
          />
        </>
      </Row>
    );
  }, [checkedList, complianceChecklist]);

  useEffect(() => {
    const isOperation = checkRoles(USER_ROLES.OPERATION, roles);
    const isCompliance = checkRoles(USER_ROLES.COMPLIANCE, roles);
    const isSpecialPermission = checkRoles(
      USER_ROLES.REVIEW_TRANSACTION,
      roles,
    );
    const isAllowed = isOperation || isCompliance || isSpecialPermission;
    setIsOperationRole(isOperation);
    setIsAllowedToMarkAsReview(isAllowed);
  }, [roles]);

  useEffect(() => {
    if (rejectUnfreezeTransaction.status === RequestStatus.Success) {
      setIsOpenRejectRequest(false);
      dispatch(transactionActions.resetRejectUnfreezeTransaction());
      onClose();
    }
  }, [rejectUnfreezeTransaction.status]);

  const footer = () => {
    if (reviewer) {
      //show unfrozen footer
      if (
        transactionDetail?.status === 'FAILED' &&
        transactionDetail?.type === 'INCOMING'
      )
        return reviewedFooter;
    } else {
      // user has type is withdraw or rebalance, role is Compliance or Operations or Special permission
      if (
        transactionDetail?.repStatus ===
          TransactionStatusResponse.PENDING_REVIEW &&
        isAllowedToMarkAsReview &&
        (transactionDetail?.type === TransactionTypeSource.REBALANCING ||
          transactionDetail?.type === TransactionTypeSource.OUTGOING)
      ) {
        return markAsReviewedFooter;
      } else if (
        transactionDetail?.repStatus ===
          TransactionStatusResponse.PENDING_REVIEW &&
        isOperationRole &&
        transactionDetail?.type === TransactionTypeSource.INCOMING
      ) {
        return pendingFooter;
      }
      // user has type is deposit, role is compliance and status is Pending review
      else if (
        transactionDetail?.type === TransactionTypeSource.INCOMING &&
        [
          SubStatus.AUTO_FREEZE,
          SubStatus.FROZEN_MANUALLY,
          SubStatus.REJECTED_AML_SCREENING,
        ].includes(transactionDetail?.subStatusFireblock) &&
        isOperationRole
      ) {
        return pendingFooter;
      }
    }
    return null;
  };

  return (
    <div className={styles.container}>
      <Drawer
        width={665}
        placement="right"
        onClose={onClose}
        forceRender
        open={open}
        keyboard={false}
        title={title}
        closable={false}
        className={styles.drawerTransaction}
        footer={isLoadingDetail ? null : footer()}
      >
        <>
          <div className={styles.detail}>
            {isLoadingDetail ? (
              <div className={styles.loading}>{Spinner}</div>
            ) : (
              <>
                <div ref={txnDetailRef}>
                  <TransactionInformation
                    tier={tier}
                    isMask={isMaskedTransaction}
                    detail={detail}
                    setSyncData={setSyncData}
                    onOpenWhiteListModal={onOpenWhiteListModal}
                  />
                </div>
                {!isStaking && (
                  <div ref={kytInfoRef}>
                    <KYTInformation />
                  </div>
                )}
                {transactionType !== 'INCOMING' && (
                  <>
                    {!!vaultPolicy?.length && (
                      <div ref={approvalLogRef}>
                        <ApprovalLog
                          approvalLogs={vaultPolicy.filter(
                            item =>
                              item.status !== StatusUserApprovalLogs.INITIATED,
                          )}
                          transactionDetail={transactionDetail}
                          numberApproved={
                            transactionDetail?.vaultPolicy?.approverNumber
                          }
                          isMaskedTransaction={isMaskedTransaction}
                        />
                      </div>
                    )}
                    {transactionDetail.logPolicyType ===
                      VAULT_POLICY_TYPE.ADVANCE &&
                      !!transactionDetail.vaultPolicy.quorums?.length && (
                        <AdvancedVaultPolicy
                          quorums={transactionDetail.vaultPolicy.quorums}
                          approverNumber={
                            transactionDetail.vaultPolicy.approverNumber
                          }
                          isMaskedTransaction={isMaskedTransaction}
                        />
                      )}
                    {/* {userIntiator && ( */}
                    <div ref={initiatorRef}>
                      <InitiatorAndApprovers
                        user={userIntiator}
                        isBlur={isMaskedTransaction}
                        approvers={usersApprover}
                      />
                    </div>
                    {/* )} */}
                    <div ref={customerRef}>
                      <Signer />
                    </div>
                  </>
                )}
                <BoxComment comments={comments} txnId={txnId} />
                <DetailTransactionChecklist
                  executeScroll={executeScroll}
                  reviewer={reviewer}
                  checklist={{
                    complianceChecklist: filteredChecklist(
                      complianceChecklist,
                      USER_ROLES.COMPLIANCE,
                    ),
                    operationsChecklist: filteredChecklist(
                      operationsChecklist,
                      USER_ROLES.OPERATION,
                    ),
                  }}
                  checkedList={checkedList}
                  setCheckedList={setCheckedList}
                  isSentRequest={
                    transactionDetail?.requestId &&
                    transactionDetail?.repStatus === 'FAILED' &&
                    transactionDetail?.type === 'INCOMING' &&
                    transactionDetail?.pendingRequests?.length !== 0
                  }
                  type={transactionDetail?.type}
                />
              </>
            )}
          </div>
        </>
      </Drawer>

      <Drawer
        width={605}
        open={isOpenWhiteListModal}
        onClose={onCloseWhiteListDetail}
        keyboard={false}
        placement="right"
        className={`${styles.drawerTransaction} ${styles.drawerTransactionWhitelist}`}
        forceRender
      >
        <div className={styles.detail}>
          {isCustomerLoading || isVaspLoading ? (
            <div className={styles.loading}>{Spinner}</div>
          ) : (
            <>
              <div style={{ marginTop: 5 }}>
                <Row justify="space-between">
                  <img src={folderIcon} alt="folder icon" />
                </Row>
                <Row
                  className={styles.reviewing__title}
                  data-testid="whitelist-title"
                >
                  Whitelisted detail
                </Row>
              </div>
              <FolderInformation folder={folder} />
              <AssetInformation
                folder={folder}
                transactionData={transactionDetail}
              />
              <WalletVerification
                folder={folder}
                transactionData={transactionDetail}
                vasp={vasp}
                customer={customer}
              />
            </>
          )}
        </div>
      </Drawer>

      <RejectTransaction
        txId={transactionDetail?.id}
        customerName={transactionDetail?.customerName}
        isOpen={isOpenRejectRequest}
        setIsOpen={setIsOpenRejectRequest}
      />

      <MarkAsReviewed
        txId={transactionDetail?.id}
        customerName={transactionDetail?.customerName}
        isOpen={isOpenMarkAsReviewed}
        setIsOpen={setIsOpenMarkAsReviewed}
      />

      <UnfreezeTransaction
        txId={transactionDetail?.id}
        customerName={transactionDetail?.customerName}
        isOpen={isOpenApproveRequest}
        setIsOpen={setIsOpenApproveRequest}
      />
    </div>
  );
};

export default DetailTransaction;
