import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Params } from 'interfaces/request';
import { PER_PAGE } from 'constants/index';
import { Sort, SortDirection } from 'enum/common';
import { SortBatchElvenTxnRAKOnChain, GroupByField, CustomerOnScreen } from 'enum/BatchElvenTxnRAKOnChain.enum';
import { RequestStatus } from 'interfaces/request';
import { ResponseData } from 'interfaces/response';
import {
  CategoryGroupByRequestParamsDTO,
  GetBatchElvenTxnRAKOnChainRequestParamDTO,
  GetBatchElvenTxnRAKOnChainResponseDTO,
  GetCategoryDTO,
  GetCompanyListDTO,
  GetCompanyListRequestParamsDTO,
  GroupByUserRequestParamsDTO,
  IBatchElvenTxnRAKOnChainDetail,
} from 'interfaces/BatchElvenTxnRAKOnChain';
import { AppState } from '../..';
import { IRakCRUDBaseControllerConfig } from 'interfaces/dynamicFilter';
interface BatchElvenTxnRAKOnChainState {
  params: GetBatchElvenTxnRAKOnChainRequestParamDTO;
  BatchElvenTxnRAKOnChain: ResponseData<GetBatchElvenTxnRAKOnChainResponseDTO>;
  BatchElvenTxnRAKOnChainPaginationConfig: ResponseData<IRakCRUDBaseControllerConfig>;
  canLoadMore: boolean;
  paramsCompany: GetCompanyListRequestParamsDTO;
  company: ResponseData<GetCompanyListDTO>;
  canLoadMoreCompany: boolean;
  usersByCompany: ResponseData<any>;
  paramsUsersByCompany: GroupByUserRequestParamsDTO;
  canLoadMoreUsers: boolean;
  category: ResponseData<GetCategoryDTO>;
  paramsCategory: CategoryGroupByRequestParamsDTO;
  type: ResponseData<any>;
  paramsType: CategoryGroupByRequestParamsDTO;
  action: ResponseData<any>;
  paramsAction: CategoryGroupByRequestParamsDTO;
  BatchElvenTxnRAKOnChainDetail: ResponseData<IBatchElvenTxnRAKOnChainDetail>;
}
export function getDefaultBatchElvenTxnRAKOnChainRequestParams() {
  return {
    [Params.Offset]: 0,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: SortBatchElvenTxnRAKOnChain.createdAt,
    [Params.Sort]: SortDirection.DESC,
    [Params.isAllRequest]: false,
  };
}
export function getDefaultCompanyRequestParams() {}
export function getDefaultCategoryGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultTypeGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultActionGroupByRequestParams() {
  return {
    groupBy: GroupByField.TYPE,
  };
}
export function getDefaultUsersParams() {
  return {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    customerIds: '',
  };
}
const initialState: BatchElvenTxnRAKOnChainState = {
  params: getDefaultBatchElvenTxnRAKOnChainRequestParams(),
  BatchElvenTxnRAKOnChain: {
    status: RequestStatus.Idle,
  },
  BatchElvenTxnRAKOnChainPaginationConfig: {
    status: RequestStatus.Idle,
  },
  canLoadMore: true,
  paramsCompany: {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: Sort.CUSTOMER_NAME,
    [Params.Sort]: SortDirection.ASC,
    [Params.SearchOnScreen]: CustomerOnScreen.BATCHELVENTXNRAKONCHAIN,
  },
  company: {
    status: RequestStatus.Idle,
  },
  canLoadMoreCompany: true,
  usersByCompany: {
    status: RequestStatus.Idle,
  },
  paramsUsersByCompany: getDefaultUsersParams(),
  canLoadMoreUsers: true,
  category: {
    status: RequestStatus.Idle,
  },
  paramsCategory: getDefaultCategoryGroupByRequestParams(),
  type: {
    status: RequestStatus.Idle,
  },
  paramsType: getDefaultTypeGroupByRequestParams(),
  action: {
    status: RequestStatus.Idle,
  },
  paramsAction: getDefaultActionGroupByRequestParams(),
  BatchElvenTxnRAKOnChainDetail: {
    status: RequestStatus.Idle,
  },
};
const getBatchElvenTxnRAKOnChainRequest: CaseReducer<BatchElvenTxnRAKOnChainState, PayloadAction<any>> = (
  state: BatchElvenTxnRAKOnChainState,
) => {
  state.BatchElvenTxnRAKOnChain.status = RequestStatus.Loading;
};
const getBatchElvenTxnRAKOnChainSuccess: CaseReducer<
  BatchElvenTxnRAKOnChainState,
  PayloadAction<GetBatchElvenTxnRAKOnChainResponseDTO>
> = (state: BatchElvenTxnRAKOnChainState, { payload }: PayloadAction<GetBatchElvenTxnRAKOnChainResponseDTO>) => {
  state.BatchElvenTxnRAKOnChain.status = RequestStatus.Success;
  state.BatchElvenTxnRAKOnChain.data = payload;
};
const getBatchElvenTxnRAKOnChainFailed: CaseReducer<BatchElvenTxnRAKOnChainState> = (state: BatchElvenTxnRAKOnChainState) => {
  state.BatchElvenTxnRAKOnChain.status = RequestStatus.Failed;
};
const getDetailBatchElvenTxnRAKOnChainRequest: CaseReducer<BatchElvenTxnRAKOnChainState, PayloadAction<string>> = (
  state: BatchElvenTxnRAKOnChainState,
) => {
  state.BatchElvenTxnRAKOnChainDetail.status = RequestStatus.Loading;
};
const getDetailBatchElvenTxnRAKOnChainSuccess: CaseReducer<
  BatchElvenTxnRAKOnChainState,
  PayloadAction<IBatchElvenTxnRAKOnChainDetail>
> = (state: BatchElvenTxnRAKOnChainState, { payload }: PayloadAction<IBatchElvenTxnRAKOnChainDetail>) => {
  state.BatchElvenTxnRAKOnChainDetail.status = RequestStatus.Success;
  state.BatchElvenTxnRAKOnChainDetail.data = payload;
};
const getDetailBatchElvenTxnRAKOnChainFailed: CaseReducer<BatchElvenTxnRAKOnChainState> = (state: BatchElvenTxnRAKOnChainState) => {
  state.BatchElvenTxnRAKOnChainDetail.status = RequestStatus.Failed;
};
const createBatchElvenTxnRAKOnChainRequest: CaseReducer<BatchElvenTxnRAKOnChainState, PayloadAction<IBatchElvenTxnRAKOnChainDetail>> = (
  state: BatchElvenTxnRAKOnChainState,
) => {
  state.BatchElvenTxnRAKOnChainDetail.status = RequestStatus.Loading;
};
const createBatchElvenTxnRAKOnChainSuccess: CaseReducer<BatchElvenTxnRAKOnChainState, PayloadAction<IBatchElvenTxnRAKOnChainDetail>> = (
  state: BatchElvenTxnRAKOnChainState,
  { payload }: PayloadAction<IBatchElvenTxnRAKOnChainDetail>,
) => {
  state.BatchElvenTxnRAKOnChainDetail.status = RequestStatus.Success;
  state.BatchElvenTxnRAKOnChainDetail.data = payload;
};
const createBatchElvenTxnRAKOnChainFailed: CaseReducer<BatchElvenTxnRAKOnChainState> = (state: BatchElvenTxnRAKOnChainState) => {
  state.BatchElvenTxnRAKOnChainDetail.status = RequestStatus.Failed;
};
const updateBatchElvenTxnRAKOnChainRequest: CaseReducer<BatchElvenTxnRAKOnChainState, PayloadAction<IBatchElvenTxnRAKOnChainDetail>> = (
  state: BatchElvenTxnRAKOnChainState,
) => {
  state.BatchElvenTxnRAKOnChainDetail.status = RequestStatus.Loading;
};
const updateBatchElvenTxnRAKOnChainSuccess: CaseReducer<BatchElvenTxnRAKOnChainState, PayloadAction<IBatchElvenTxnRAKOnChainDetail>> = (
  state: BatchElvenTxnRAKOnChainState,
  { payload }: PayloadAction<IBatchElvenTxnRAKOnChainDetail>,
) => {
  state.BatchElvenTxnRAKOnChainDetail.status = RequestStatus.Success;
  state.BatchElvenTxnRAKOnChainDetail.data = payload;
};
const updateBatchElvenTxnRAKOnChainFailed: CaseReducer<BatchElvenTxnRAKOnChainState> = (state: BatchElvenTxnRAKOnChainState) => {
  state.BatchElvenTxnRAKOnChainDetail.status = RequestStatus.Failed;
};
const appendBatchElvenTxnRAKOnChain: CaseReducer<BatchElvenTxnRAKOnChainState, PayloadAction<any>> = (
  state: BatchElvenTxnRAKOnChainState,
  { payload }: PayloadAction<any>,
) => {
  if (state.BatchElvenTxnRAKOnChain?.data?.listBatchElvenTxnRAKOnChain) {
    state.BatchElvenTxnRAKOnChain.status = RequestStatus.Success;
    state.BatchElvenTxnRAKOnChain.data.listBatchElvenTxnRAKOnChain = [...payload];
  }
};
const setCanLoadMore = (state: BatchElvenTxnRAKOnChainState, { payload }: PayloadAction<any>) => {
  state.canLoadMore = payload;
};
const resetBatchElvenTxnRAKOnChain: CaseReducer<BatchElvenTxnRAKOnChainState> = (state: BatchElvenTxnRAKOnChainState) => {
  state.BatchElvenTxnRAKOnChain = {
    status: RequestStatus.Idle,
  };
};
const resetBatchElvenTxnRAKOnChainParams: CaseReducer<BatchElvenTxnRAKOnChainState> = (state: BatchElvenTxnRAKOnChainState) => {
  state.params = getDefaultBatchElvenTxnRAKOnChainRequestParams();
};
const setBatchElvenTxnRAKOnChainFilterParams = (
  state: BatchElvenTxnRAKOnChainState,
  { payload }: PayloadAction<GetBatchElvenTxnRAKOnChainRequestParamDTO>,
) => {
  state.params = payload;
};
// TODO: Action
const fetchActionRequest: CaseReducer<
  BatchElvenTxnRAKOnChainState,
  PayloadAction<CategoryGroupByRequestParamsDTO>
> = (state: BatchElvenTxnRAKOnChainState) => {
  state.action.status = RequestStatus.Loading;
};
const fetchActionSuccess: CaseReducer<BatchElvenTxnRAKOnChainState, PayloadAction<any>> = (
  state: BatchElvenTxnRAKOnChainState,
  { payload }: PayloadAction<any>,
) => {
  state.action.status = RequestStatus.Success;
  state.action.data = payload;
};
const fetchActionFailed: CaseReducer<BatchElvenTxnRAKOnChainState> = (state: BatchElvenTxnRAKOnChainState) => {
  state.action.status = RequestStatus.Failed;
};
const getBatchElvenTxnRAKOnChainPaginationConfigRequest: CaseReducer<
  BatchElvenTxnRAKOnChainState,
  PayloadAction<any>
> = (state: BatchElvenTxnRAKOnChainState) => {
  state.BatchElvenTxnRAKOnChainPaginationConfig.status = RequestStatus.Loading;
};
const getBatchElvenTxnRAKOnChainPaginationConfigSuccess: CaseReducer<
  BatchElvenTxnRAKOnChainState,
  PayloadAction<IRakCRUDBaseControllerConfig>
> = (
  state: BatchElvenTxnRAKOnChainState,
  { payload }: PayloadAction<IRakCRUDBaseControllerConfig>,
) => {
  state.BatchElvenTxnRAKOnChainPaginationConfig.status = RequestStatus.Success;
  state.BatchElvenTxnRAKOnChainPaginationConfig.data = payload;
};
const getBatchElvenTxnRAKOnChainPaginationConfigFailed: CaseReducer<BatchElvenTxnRAKOnChainState> = (
  state: BatchElvenTxnRAKOnChainState,
) => {
  state.BatchElvenTxnRAKOnChainPaginationConfig.status = RequestStatus.Failed;
};
const BatchElvenTxnRAKOnChainSlice = createSlice({
  name: 'BatchElvenTxnRAKOnChain',
  initialState,
  reducers: {
    getBatchElvenTxnRAKOnChainRequest,
    getBatchElvenTxnRAKOnChainSuccess,
    getBatchElvenTxnRAKOnChainFailed,
    getDetailBatchElvenTxnRAKOnChainRequest,
    getDetailBatchElvenTxnRAKOnChainSuccess,
    getDetailBatchElvenTxnRAKOnChainFailed,
    createBatchElvenTxnRAKOnChainRequest,
    createBatchElvenTxnRAKOnChainSuccess,
    createBatchElvenTxnRAKOnChainFailed,
    updateBatchElvenTxnRAKOnChainRequest,
    updateBatchElvenTxnRAKOnChainSuccess,
    updateBatchElvenTxnRAKOnChainFailed,
    appendBatchElvenTxnRAKOnChain,
    setCanLoadMore,
    resetBatchElvenTxnRAKOnChain,
    setBatchElvenTxnRAKOnChainParams<K extends keyof GetBatchElvenTxnRAKOnChainRequestParamDTO>(
      state: BatchElvenTxnRAKOnChainState,
      action: PayloadAction<{
        key: K;
        value: GetBatchElvenTxnRAKOnChainRequestParamDTO[K];
      }>,
    ) {
      state.params[action.payload.key] = action.payload.value;
      if (action.payload.key === Params.KeyWord) {
        state.params[Params.KeyWord.toString()] = action.payload.value;
      }
    },
    setBatchElvenTxnRAKOnChainFilterParams,
    resetBatchElvenTxnRAKOnChainParams,
    // Action
    fetchActionRequest,
    fetchActionSuccess,
    fetchActionFailed,
    // pagination
    getBatchElvenTxnRAKOnChainPaginationConfigRequest,
    getBatchElvenTxnRAKOnChainPaginationConfigSuccess,
    getBatchElvenTxnRAKOnChainPaginationConfigFailed,
  },
});
// Actions
export const BatchElvenTxnRAKOnChainActions = BatchElvenTxnRAKOnChainSlice.actions;
// Reducer
export const BatchElvenTxnRAKOnChainReducer = BatchElvenTxnRAKOnChainSlice.reducer;
// Selectors
export const selectBatchElvenTxnRAKOnChain = (state: AppState) => state.BatchElvenTxnRAKOnChain?.BatchElvenTxnRAKOnChain;
export const selectParamBatchElvenTxnRAKOnChain = (state: AppState) => state.BatchElvenTxnRAKOnChain?.params;
export const selectCanLoadMoreBatchElvenTxnRAKOnChain = (state: AppState) =>
  state.BatchElvenTxnRAKOnChain?.canLoadMore;
export const selectBatchElvenTxnRAKOnChainDetail = (state: AppState) => state.BatchElvenTxnRAKOnChain?.BatchElvenTxnRAKOnChainDetail;
export const selectBatchElvenTxnRAKOnChainPagination = (state: AppState) =>
  state.BatchElvenTxnRAKOnChain?.BatchElvenTxnRAKOnChainPaginationConfig;
