import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Params } from 'interfaces/request';
import { PER_PAGE } from 'constants/index';
import { Sort, SortDirection } from 'enum/common';
import {
  SortExportType,
  GroupByField,
  CustomerOnScreen,
} from 'enum/ExportType.enum';
import { RequestStatus } from 'interfaces/request';
import { ResponseData } from 'interfaces/response';
import {
  CategoryGroupByRequestParamsDTO,
  GetExportTypeRequestParamDTO,
  GetExportTypeResponseDTO,
  GetCategoryDTO,
  GetCompanyListDTO,
  GetCompanyListRequestParamsDTO,
  GroupByUserRequestParamsDTO,
  IExportTypeDetail,
} from 'interfaces/ExportType';
import { AppState } from '../..';
import { IRakCRUDBaseControllerConfig } from 'interfaces/dynamicFilter';
interface ExportTypeState {
  params: GetExportTypeRequestParamDTO;
  ExportType: ResponseData<GetExportTypeResponseDTO>;
  ExportTypePaginationConfig: ResponseData<IRakCRUDBaseControllerConfig>;
  canLoadMore: boolean;
  paramsCompany: GetCompanyListRequestParamsDTO;
  company: ResponseData<GetCompanyListDTO>;
  canLoadMoreCompany: boolean;
  usersByCompany: ResponseData<any>;
  paramsUsersByCompany: GroupByUserRequestParamsDTO;
  canLoadMoreUsers: boolean;
  category: ResponseData<GetCategoryDTO>;
  paramsCategory: CategoryGroupByRequestParamsDTO;
  type: ResponseData<any>;
  paramsType: CategoryGroupByRequestParamsDTO;
  action: ResponseData<any>;
  paramsAction: CategoryGroupByRequestParamsDTO;
  ExportTypeDetail: ResponseData<IExportTypeDetail>;
}
export function getDefaultExportTypeRequestParams() {
  return {
    [Params.Offset]: 0,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: SortExportType.createdAt,
    [Params.Sort]: SortDirection.DESC,
    [Params.isAllRequest]: false,
  };
}
export function getDefaultCompanyRequestParams() {}
export function getDefaultCategoryGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultTypeGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultActionGroupByRequestParams() {
  return {
    groupBy: GroupByField.TYPE,
  };
}
export function getDefaultUsersParams() {
  return {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    customerIds: '',
  };
}
const initialState: ExportTypeState = {
  params: getDefaultExportTypeRequestParams(),
  ExportType: {
    status: RequestStatus.Idle,
  },
  ExportTypePaginationConfig: {
    status: RequestStatus.Idle,
  },
  canLoadMore: true,
  paramsCompany: {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: Sort.CUSTOMER_NAME,
    [Params.Sort]: SortDirection.ASC,
    [Params.SearchOnScreen]: CustomerOnScreen.EXPORTTYPE,
  },
  company: {
    status: RequestStatus.Idle,
  },
  canLoadMoreCompany: true,
  usersByCompany: {
    status: RequestStatus.Idle,
  },
  paramsUsersByCompany: getDefaultUsersParams(),
  canLoadMoreUsers: true,
  category: {
    status: RequestStatus.Idle,
  },
  paramsCategory: getDefaultCategoryGroupByRequestParams(),
  type: {
    status: RequestStatus.Idle,
  },
  paramsType: getDefaultTypeGroupByRequestParams(),
  action: {
    status: RequestStatus.Idle,
  },
  paramsAction: getDefaultActionGroupByRequestParams(),
  ExportTypeDetail: {
    status: RequestStatus.Idle,
  },
};
const getExportTypeRequest: CaseReducer<ExportTypeState, PayloadAction<any>> = (
  state: ExportTypeState,
) => {
  state.ExportType.status = RequestStatus.Loading;
};
const getExportTypeSuccess: CaseReducer<
  ExportTypeState,
  PayloadAction<GetExportTypeResponseDTO>
> = (
  state: ExportTypeState,
  { payload }: PayloadAction<GetExportTypeResponseDTO>,
) => {
  state.ExportType.status = RequestStatus.Success;
  state.ExportType.data = payload;
};
const getExportTypeFailed: CaseReducer<ExportTypeState> = (
  state: ExportTypeState,
) => {
  state.ExportType.status = RequestStatus.Failed;
};
const getDetailExportTypeRequest: CaseReducer<
  ExportTypeState,
  PayloadAction<string>
> = (state: ExportTypeState) => {
  state.ExportTypeDetail.status = RequestStatus.Loading;
};
const getDetailExportTypeSuccess: CaseReducer<
  ExportTypeState,
  PayloadAction<IExportTypeDetail>
> = (state: ExportTypeState, { payload }: PayloadAction<IExportTypeDetail>) => {
  state.ExportTypeDetail.status = RequestStatus.Success;
  state.ExportTypeDetail.data = payload;
};
const getDetailExportTypeFailed: CaseReducer<ExportTypeState> = (
  state: ExportTypeState,
) => {
  state.ExportTypeDetail.status = RequestStatus.Failed;
};
const createExportTypeRequest: CaseReducer<
  ExportTypeState,
  PayloadAction<IExportTypeDetail>
> = (state: ExportTypeState) => {
  state.ExportTypeDetail.status = RequestStatus.Loading;
};
const createExportTypeSuccess: CaseReducer<
  ExportTypeState,
  PayloadAction<IExportTypeDetail>
> = (state: ExportTypeState, { payload }: PayloadAction<IExportTypeDetail>) => {
  state.ExportTypeDetail.status = RequestStatus.Success;
  state.ExportTypeDetail.data = payload;
};
const createExportTypeFailed: CaseReducer<ExportTypeState> = (
  state: ExportTypeState,
) => {
  state.ExportTypeDetail.status = RequestStatus.Failed;
};
const updateExportTypeRequest: CaseReducer<
  ExportTypeState,
  PayloadAction<IExportTypeDetail>
> = (state: ExportTypeState) => {
  state.ExportTypeDetail.status = RequestStatus.Loading;
};
const updateExportTypeSuccess: CaseReducer<
  ExportTypeState,
  PayloadAction<IExportTypeDetail>
> = (state: ExportTypeState, { payload }: PayloadAction<IExportTypeDetail>) => {
  state.ExportTypeDetail.status = RequestStatus.Success;
  state.ExportTypeDetail.data = payload;
};
const updateExportTypeFailed: CaseReducer<ExportTypeState> = (
  state: ExportTypeState,
) => {
  state.ExportTypeDetail.status = RequestStatus.Failed;
};
const appendExportType: CaseReducer<ExportTypeState, PayloadAction<any>> = (
  state: ExportTypeState,
  { payload }: PayloadAction<any>,
) => {
  if (state.ExportType?.data?.listExportType) {
    state.ExportType.status = RequestStatus.Success;
    state.ExportType.data.listExportType = [...payload];
  }
};
const setCanLoadMore = (
  state: ExportTypeState,
  { payload }: PayloadAction<any>,
) => {
  state.canLoadMore = payload;
};
const resetExportType: CaseReducer<ExportTypeState> = (
  state: ExportTypeState,
) => {
  state.ExportType = {
    status: RequestStatus.Idle,
  };
};
const resetExportTypeParams: CaseReducer<ExportTypeState> = (
  state: ExportTypeState,
) => {
  state.params = getDefaultExportTypeRequestParams();
};
const setExportTypeFilterParams = (
  state: ExportTypeState,
  { payload }: PayloadAction<GetExportTypeRequestParamDTO>,
) => {
  state.params = payload;
};
// TODO: Action
const fetchActionRequest: CaseReducer<
  ExportTypeState,
  PayloadAction<CategoryGroupByRequestParamsDTO>
> = (state: ExportTypeState) => {
  state.action.status = RequestStatus.Loading;
};
const fetchActionSuccess: CaseReducer<ExportTypeState, PayloadAction<any>> = (
  state: ExportTypeState,
  { payload }: PayloadAction<any>,
) => {
  state.action.status = RequestStatus.Success;
  state.action.data = payload;
};
const fetchActionFailed: CaseReducer<ExportTypeState> = (
  state: ExportTypeState,
) => {
  state.action.status = RequestStatus.Failed;
};
const getExportTypePaginationConfigRequest: CaseReducer<
  ExportTypeState,
  PayloadAction<any>
> = (state: ExportTypeState) => {
  state.ExportTypePaginationConfig.status = RequestStatus.Loading;
};
const getExportTypePaginationConfigSuccess: CaseReducer<
  ExportTypeState,
  PayloadAction<IRakCRUDBaseControllerConfig>
> = (
  state: ExportTypeState,
  { payload }: PayloadAction<IRakCRUDBaseControllerConfig>,
) => {
  state.ExportTypePaginationConfig.status = RequestStatus.Success;
  state.ExportTypePaginationConfig.data = payload;
};
const getExportTypePaginationConfigFailed: CaseReducer<ExportTypeState> = (
  state: ExportTypeState,
) => {
  state.ExportTypePaginationConfig.status = RequestStatus.Failed;
};
const ExportTypeSlice = createSlice({
  name: 'ExportType',
  initialState,
  reducers: {
    getExportTypeRequest,
    getExportTypeSuccess,
    getExportTypeFailed,
    getDetailExportTypeRequest,
    getDetailExportTypeSuccess,
    getDetailExportTypeFailed,
    createExportTypeRequest,
    createExportTypeSuccess,
    createExportTypeFailed,
    updateExportTypeRequest,
    updateExportTypeSuccess,
    updateExportTypeFailed,
    appendExportType,
    setCanLoadMore,
    resetExportType,
    setExportTypeParams<K extends keyof GetExportTypeRequestParamDTO>(
      state: ExportTypeState,
      action: PayloadAction<{
        key: K;
        value: GetExportTypeRequestParamDTO[K];
      }>,
    ) {
      state.params[action.payload.key] = action.payload.value;
      if (action.payload.key === Params.KeyWord) {
        state.params[Params.KeyWord.toString()] = action.payload.value;
      }
    },
    setExportTypeFilterParams,
    resetExportTypeParams,
    // Action
    fetchActionRequest,
    fetchActionSuccess,
    fetchActionFailed,
    // pagination
    getExportTypePaginationConfigRequest,
    getExportTypePaginationConfigSuccess,
    getExportTypePaginationConfigFailed,
  },
});
// Actions
export const ExportTypeActions = ExportTypeSlice.actions;
// Reducer
export const ExportTypeReducer = ExportTypeSlice.reducer;
// Selectors
export const selectExportType = (state: AppState) =>
  state.ExportType?.ExportType;
export const selectParamExportType = (state: AppState) =>
  state.ExportType?.params;
export const selectCanLoadMoreExportType = (state: AppState) =>
  state.ExportType?.canLoadMore;
export const selectExportTypeDetail = (state: AppState) =>
  state.ExportType?.ExportTypeDetail;
export const selectExportTypePagination = (state: AppState) =>
  state.ExportType?.ExportTypePaginationConfig;
