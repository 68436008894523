import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  getExportTypeRequest,
  getDetailExportType,
  createExportTypeRequest,
  updateExportTypeRequest,
  getExportTypeRequestPaginationConfig,
} from 'services/ExportType.service';
import { ExportTypeActions } from 'store/slices/ExportType/ExportType.slice';
import { handleShowMsgErr } from 'utils/common';
import {
  GetExportTypeRequestParamDTO,
  ICreateExportType,
} from 'interfaces/ExportType';
const handleGetExportTypeRequest = function* ({
  payload,
}: PayloadAction<GetExportTypeRequestParamDTO>) {
  try {
    const res = yield call(getExportTypeRequest, payload);
    const params = yield select(state => state.ExportType.params) as any;
    const listExportType = yield select(
      state => state.ExportType?.ExportType?.data?.listExportType,
    ) as any;
    if (res) {
      yield put(
        ExportTypeActions.setCanLoadMore(
          params.offset + params.limit < res.total,
        ),
      );
      if (params.offset > 0) {
        const newList: any = [...(listExportType || []), ...res.list];
        yield put(ExportTypeActions.appendExportType(newList));
      } else {
        yield put(
          ExportTypeActions.getExportTypeSuccess({
            listExportType: res.list,
            total: res.total,
          }),
        );
      }
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(ExportTypeActions.getExportTypeFailed());
  }
};
const handleGetDetailExportTypeRequest = function* ({
  payload,
}: PayloadAction<string>) {
  try {
    const res = yield call(getDetailExportType, payload);
    if (res) {
      yield put(ExportTypeActions.getDetailExportTypeSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(ExportTypeActions.getDetailExportTypeFailed());
  }
};
const handleCreateExportTypeRequest = function* ({
  payload,
}: PayloadAction<ICreateExportType>) {
  try {
    const res = yield call(createExportTypeRequest, payload);
    if (res) {
      yield put(ExportTypeActions.createExportTypeSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(ExportTypeActions.createExportTypeFailed());
  }
};
const handleUpdateExportTypeRequest = function* ({
  payload,
}: PayloadAction<ICreateExportType>) {
  try {
    const res = yield call(updateExportTypeRequest, payload);
    if (res) {
      yield put(ExportTypeActions.updateExportTypeSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(ExportTypeActions.updateExportTypeFailed());
  }
};
const handleGetExportTypePaginationConfigRequest = function* ({
  payload,
}: PayloadAction<GetExportTypeRequestParamDTO>) {
  try {
    const res = yield call(getExportTypeRequestPaginationConfig);
    if (res) {
      yield put(ExportTypeActions.getExportTypePaginationConfigSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(ExportTypeActions.getExportTypePaginationConfigFailed());
  }
};
const ExportTypeSaga = function* () {
  yield takeLatest(
    ExportTypeActions.getExportTypeRequest.type,
    handleGetExportTypeRequest,
  );
  yield takeLatest(
    ExportTypeActions.createExportTypeRequest.type,
    handleCreateExportTypeRequest,
  );
  yield takeLatest(
    ExportTypeActions.updateExportTypeRequest.type,
    handleUpdateExportTypeRequest,
  );
  yield takeLatest(
    ExportTypeActions.getExportTypePaginationConfigRequest.type,
    handleGetExportTypePaginationConfigRequest,
  );
  yield takeLatest(
    ExportTypeActions.getDetailExportTypeRequest.type,
    handleGetDetailExportTypeRequest,
  );
};
export default ExportTypeSaga;
