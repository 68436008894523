import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Params } from 'interfaces/request';
import { PER_PAGE } from 'constants/index';
import { Sort, SortDirection } from 'enum/common';
import { SortBatchElvenTxnRAKFB, GroupByField, CustomerOnScreen } from 'enum/BatchElvenTxnRAKFB.enum';
import { RequestStatus } from 'interfaces/request';
import { ResponseData } from 'interfaces/response';
import {
  CategoryGroupByRequestParamsDTO,
  GetBatchElvenTxnRAKFBRequestParamDTO,
  GetBatchElvenTxnRAKFBResponseDTO,
  GetCategoryDTO,
  GetCompanyListDTO,
  GetCompanyListRequestParamsDTO,
  GroupByUserRequestParamsDTO,
  IBatchElvenTxnRAKFBDetail,
} from 'interfaces/BatchElvenTxnRAKFB';
import { AppState } from '../..';
import { IRakCRUDBaseControllerConfig } from 'interfaces/dynamicFilter';
interface BatchElvenTxnRAKFBState {
  params: GetBatchElvenTxnRAKFBRequestParamDTO;
  BatchElvenTxnRAKFB: ResponseData<GetBatchElvenTxnRAKFBResponseDTO>;
  BatchElvenTxnRAKFBPaginationConfig: ResponseData<IRakCRUDBaseControllerConfig>;
  canLoadMore: boolean;
  paramsCompany: GetCompanyListRequestParamsDTO;
  company: ResponseData<GetCompanyListDTO>;
  canLoadMoreCompany: boolean;
  usersByCompany: ResponseData<any>;
  paramsUsersByCompany: GroupByUserRequestParamsDTO;
  canLoadMoreUsers: boolean;
  category: ResponseData<GetCategoryDTO>;
  paramsCategory: CategoryGroupByRequestParamsDTO;
  type: ResponseData<any>;
  paramsType: CategoryGroupByRequestParamsDTO;
  action: ResponseData<any>;
  paramsAction: CategoryGroupByRequestParamsDTO;
  BatchElvenTxnRAKFBDetail: ResponseData<IBatchElvenTxnRAKFBDetail>;
}
export function getDefaultBatchElvenTxnRAKFBRequestParams() {
  return {
    [Params.Offset]: 0,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: SortBatchElvenTxnRAKFB.createdAt,
    [Params.Sort]: SortDirection.DESC,
    [Params.isAllRequest]: false,
  };
}
export function getDefaultCompanyRequestParams() {}
export function getDefaultCategoryGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultTypeGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultActionGroupByRequestParams() {
  return {
    groupBy: GroupByField.TYPE,
  };
}
export function getDefaultUsersParams() {
  return {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    customerIds: '',
  };
}
const initialState: BatchElvenTxnRAKFBState = {
  params: getDefaultBatchElvenTxnRAKFBRequestParams(),
  BatchElvenTxnRAKFB: {
    status: RequestStatus.Idle,
  },
  BatchElvenTxnRAKFBPaginationConfig: {
    status: RequestStatus.Idle,
  },
  canLoadMore: true,
  paramsCompany: {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: Sort.CUSTOMER_NAME,
    [Params.Sort]: SortDirection.ASC,
    [Params.SearchOnScreen]: CustomerOnScreen.BATCHELVENTXNRAKFB,
  },
  company: {
    status: RequestStatus.Idle,
  },
  canLoadMoreCompany: true,
  usersByCompany: {
    status: RequestStatus.Idle,
  },
  paramsUsersByCompany: getDefaultUsersParams(),
  canLoadMoreUsers: true,
  category: {
    status: RequestStatus.Idle,
  },
  paramsCategory: getDefaultCategoryGroupByRequestParams(),
  type: {
    status: RequestStatus.Idle,
  },
  paramsType: getDefaultTypeGroupByRequestParams(),
  action: {
    status: RequestStatus.Idle,
  },
  paramsAction: getDefaultActionGroupByRequestParams(),
  BatchElvenTxnRAKFBDetail: {
    status: RequestStatus.Idle,
  },
};
const getBatchElvenTxnRAKFBRequest: CaseReducer<BatchElvenTxnRAKFBState, PayloadAction<any>> = (
  state: BatchElvenTxnRAKFBState,
) => {
  state.BatchElvenTxnRAKFB.status = RequestStatus.Loading;
};
const getBatchElvenTxnRAKFBSuccess: CaseReducer<
  BatchElvenTxnRAKFBState,
  PayloadAction<GetBatchElvenTxnRAKFBResponseDTO>
> = (state: BatchElvenTxnRAKFBState, { payload }: PayloadAction<GetBatchElvenTxnRAKFBResponseDTO>) => {
  state.BatchElvenTxnRAKFB.status = RequestStatus.Success;
  state.BatchElvenTxnRAKFB.data = payload;
};
const getBatchElvenTxnRAKFBFailed: CaseReducer<BatchElvenTxnRAKFBState> = (state: BatchElvenTxnRAKFBState) => {
  state.BatchElvenTxnRAKFB.status = RequestStatus.Failed;
};
const getDetailBatchElvenTxnRAKFBRequest: CaseReducer<BatchElvenTxnRAKFBState, PayloadAction<string>> = (
  state: BatchElvenTxnRAKFBState,
) => {
  state.BatchElvenTxnRAKFBDetail.status = RequestStatus.Loading;
};
const getDetailBatchElvenTxnRAKFBSuccess: CaseReducer<
  BatchElvenTxnRAKFBState,
  PayloadAction<IBatchElvenTxnRAKFBDetail>
> = (state: BatchElvenTxnRAKFBState, { payload }: PayloadAction<IBatchElvenTxnRAKFBDetail>) => {
  state.BatchElvenTxnRAKFBDetail.status = RequestStatus.Success;
  state.BatchElvenTxnRAKFBDetail.data = payload;
};
const getDetailBatchElvenTxnRAKFBFailed: CaseReducer<BatchElvenTxnRAKFBState> = (state: BatchElvenTxnRAKFBState) => {
  state.BatchElvenTxnRAKFBDetail.status = RequestStatus.Failed;
};
const createBatchElvenTxnRAKFBRequest: CaseReducer<BatchElvenTxnRAKFBState, PayloadAction<IBatchElvenTxnRAKFBDetail>> = (
  state: BatchElvenTxnRAKFBState,
) => {
  state.BatchElvenTxnRAKFBDetail.status = RequestStatus.Loading;
};
const createBatchElvenTxnRAKFBSuccess: CaseReducer<BatchElvenTxnRAKFBState, PayloadAction<IBatchElvenTxnRAKFBDetail>> = (
  state: BatchElvenTxnRAKFBState,
  { payload }: PayloadAction<IBatchElvenTxnRAKFBDetail>,
) => {
  state.BatchElvenTxnRAKFBDetail.status = RequestStatus.Success;
  state.BatchElvenTxnRAKFBDetail.data = payload;
};
const createBatchElvenTxnRAKFBFailed: CaseReducer<BatchElvenTxnRAKFBState> = (state: BatchElvenTxnRAKFBState) => {
  state.BatchElvenTxnRAKFBDetail.status = RequestStatus.Failed;
};
const updateBatchElvenTxnRAKFBRequest: CaseReducer<BatchElvenTxnRAKFBState, PayloadAction<IBatchElvenTxnRAKFBDetail>> = (
  state: BatchElvenTxnRAKFBState,
) => {
  state.BatchElvenTxnRAKFBDetail.status = RequestStatus.Loading;
};
const updateBatchElvenTxnRAKFBSuccess: CaseReducer<BatchElvenTxnRAKFBState, PayloadAction<IBatchElvenTxnRAKFBDetail>> = (
  state: BatchElvenTxnRAKFBState,
  { payload }: PayloadAction<IBatchElvenTxnRAKFBDetail>,
) => {
  state.BatchElvenTxnRAKFBDetail.status = RequestStatus.Success;
  state.BatchElvenTxnRAKFBDetail.data = payload;
};
const updateBatchElvenTxnRAKFBFailed: CaseReducer<BatchElvenTxnRAKFBState> = (state: BatchElvenTxnRAKFBState) => {
  state.BatchElvenTxnRAKFBDetail.status = RequestStatus.Failed;
};
const appendBatchElvenTxnRAKFB: CaseReducer<BatchElvenTxnRAKFBState, PayloadAction<any>> = (
  state: BatchElvenTxnRAKFBState,
  { payload }: PayloadAction<any>,
) => {
  if (state.BatchElvenTxnRAKFB?.data?.listBatchElvenTxnRAKFB) {
    state.BatchElvenTxnRAKFB.status = RequestStatus.Success;
    state.BatchElvenTxnRAKFB.data.listBatchElvenTxnRAKFB = [...payload];
  }
};
const setCanLoadMore = (state: BatchElvenTxnRAKFBState, { payload }: PayloadAction<any>) => {
  state.canLoadMore = payload;
};
const resetBatchElvenTxnRAKFB: CaseReducer<BatchElvenTxnRAKFBState> = (state: BatchElvenTxnRAKFBState) => {
  state.BatchElvenTxnRAKFB = {
    status: RequestStatus.Idle,
  };
};
const resetBatchElvenTxnRAKFBParams: CaseReducer<BatchElvenTxnRAKFBState> = (state: BatchElvenTxnRAKFBState) => {
  state.params = getDefaultBatchElvenTxnRAKFBRequestParams();
};
const setBatchElvenTxnRAKFBFilterParams = (
  state: BatchElvenTxnRAKFBState,
  { payload }: PayloadAction<GetBatchElvenTxnRAKFBRequestParamDTO>,
) => {
  state.params = payload;
};
// TODO: Action
const fetchActionRequest: CaseReducer<
  BatchElvenTxnRAKFBState,
  PayloadAction<CategoryGroupByRequestParamsDTO>
> = (state: BatchElvenTxnRAKFBState) => {
  state.action.status = RequestStatus.Loading;
};
const fetchActionSuccess: CaseReducer<BatchElvenTxnRAKFBState, PayloadAction<any>> = (
  state: BatchElvenTxnRAKFBState,
  { payload }: PayloadAction<any>,
) => {
  state.action.status = RequestStatus.Success;
  state.action.data = payload;
};
const fetchActionFailed: CaseReducer<BatchElvenTxnRAKFBState> = (state: BatchElvenTxnRAKFBState) => {
  state.action.status = RequestStatus.Failed;
};
const getBatchElvenTxnRAKFBPaginationConfigRequest: CaseReducer<
  BatchElvenTxnRAKFBState,
  PayloadAction<any>
> = (state: BatchElvenTxnRAKFBState) => {
  state.BatchElvenTxnRAKFBPaginationConfig.status = RequestStatus.Loading;
};
const getBatchElvenTxnRAKFBPaginationConfigSuccess: CaseReducer<
  BatchElvenTxnRAKFBState,
  PayloadAction<IRakCRUDBaseControllerConfig>
> = (
  state: BatchElvenTxnRAKFBState,
  { payload }: PayloadAction<IRakCRUDBaseControllerConfig>,
) => {
  state.BatchElvenTxnRAKFBPaginationConfig.status = RequestStatus.Success;
  state.BatchElvenTxnRAKFBPaginationConfig.data = payload;
};
const getBatchElvenTxnRAKFBPaginationConfigFailed: CaseReducer<BatchElvenTxnRAKFBState> = (
  state: BatchElvenTxnRAKFBState,
) => {
  state.BatchElvenTxnRAKFBPaginationConfig.status = RequestStatus.Failed;
};
const BatchElvenTxnRAKFBSlice = createSlice({
  name: 'BatchElvenTxnRAKFB',
  initialState,
  reducers: {
    getBatchElvenTxnRAKFBRequest,
    getBatchElvenTxnRAKFBSuccess,
    getBatchElvenTxnRAKFBFailed,
    getDetailBatchElvenTxnRAKFBRequest,
    getDetailBatchElvenTxnRAKFBSuccess,
    getDetailBatchElvenTxnRAKFBFailed,
    createBatchElvenTxnRAKFBRequest,
    createBatchElvenTxnRAKFBSuccess,
    createBatchElvenTxnRAKFBFailed,
    updateBatchElvenTxnRAKFBRequest,
    updateBatchElvenTxnRAKFBSuccess,
    updateBatchElvenTxnRAKFBFailed,
    appendBatchElvenTxnRAKFB,
    setCanLoadMore,
    resetBatchElvenTxnRAKFB,
    setBatchElvenTxnRAKFBParams<K extends keyof GetBatchElvenTxnRAKFBRequestParamDTO>(
      state: BatchElvenTxnRAKFBState,
      action: PayloadAction<{
        key: K;
        value: GetBatchElvenTxnRAKFBRequestParamDTO[K];
      }>,
    ) {
      state.params[action.payload.key] = action.payload.value;
      if (action.payload.key === Params.KeyWord) {
        state.params[Params.KeyWord.toString()] = action.payload.value;
      }
    },
    setBatchElvenTxnRAKFBFilterParams,
    resetBatchElvenTxnRAKFBParams,
    // Action
    fetchActionRequest,
    fetchActionSuccess,
    fetchActionFailed,
    // pagination
    getBatchElvenTxnRAKFBPaginationConfigRequest,
    getBatchElvenTxnRAKFBPaginationConfigSuccess,
    getBatchElvenTxnRAKFBPaginationConfigFailed,
  },
});
// Actions
export const BatchElvenTxnRAKFBActions = BatchElvenTxnRAKFBSlice.actions;
// Reducer
export const BatchElvenTxnRAKFBReducer = BatchElvenTxnRAKFBSlice.reducer;
// Selectors
export const selectBatchElvenTxnRAKFB = (state: AppState) => state.BatchElvenTxnRAKFB?.BatchElvenTxnRAKFB;
export const selectParamBatchElvenTxnRAKFB = (state: AppState) => state.BatchElvenTxnRAKFB?.params;
export const selectCanLoadMoreBatchElvenTxnRAKFB = (state: AppState) =>
  state.BatchElvenTxnRAKFB?.canLoadMore;
export const selectBatchElvenTxnRAKFBDetail = (state: AppState) => state.BatchElvenTxnRAKFB?.BatchElvenTxnRAKFBDetail;
export const selectBatchElvenTxnRAKFBPagination = (state: AppState) =>
  state.BatchElvenTxnRAKFB?.BatchElvenTxnRAKFBPaginationConfig;
