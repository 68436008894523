import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Params } from 'interfaces/request';
import { PER_PAGE } from 'constants/index';
import { Sort, SortDirection } from 'enum/common';
import { SortTravelRuleVASP, GroupByField, CustomerOnScreen } from 'enum/TravelRuleVASP.enum';
import { RequestStatus } from 'interfaces/request';
import { ResponseData } from 'interfaces/response';
import {
  CategoryGroupByRequestParamsDTO,
  GetTravelRuleVASPRequestParamDTO,
  GetTravelRuleVASPResponseDTO,
  GetCategoryDTO,
  GetCompanyListDTO,
  GetCompanyListRequestParamsDTO,
  GroupByUserRequestParamsDTO,
  ITravelRuleVASPDetail,
} from 'interfaces/TravelRuleVASP';
import { AppState } from '../..';
import { IRakCRUDBaseControllerConfig } from 'interfaces/dynamicFilter';
interface TravelRuleVASPState {
  params: GetTravelRuleVASPRequestParamDTO;
  TravelRuleVASP: ResponseData<GetTravelRuleVASPResponseDTO>;
  TravelRuleVASPPaginationConfig: ResponseData<IRakCRUDBaseControllerConfig>;
  canLoadMore: boolean;
  paramsCompany: GetCompanyListRequestParamsDTO;
  company: ResponseData<GetCompanyListDTO>;
  canLoadMoreCompany: boolean;
  usersByCompany: ResponseData<any>;
  paramsUsersByCompany: GroupByUserRequestParamsDTO;
  canLoadMoreUsers: boolean;
  category: ResponseData<GetCategoryDTO>;
  paramsCategory: CategoryGroupByRequestParamsDTO;
  type: ResponseData<any>;
  paramsType: CategoryGroupByRequestParamsDTO;
  action: ResponseData<any>;
  paramsAction: CategoryGroupByRequestParamsDTO;
  TravelRuleVASPDetail: ResponseData<ITravelRuleVASPDetail>;
}
export function getDefaultTravelRuleVASPRequestParams() {
  return {
    [Params.Offset]: 0,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: SortTravelRuleVASP.createdAt,
    [Params.Sort]: SortDirection.DESC,
    [Params.isAllRequest]: false,
  };
}
export function getDefaultCompanyRequestParams() {}
export function getDefaultCategoryGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultTypeGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultActionGroupByRequestParams() {
  return {
    groupBy: GroupByField.TYPE,
  };
}
export function getDefaultUsersParams() {
  return {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    customerIds: '',
  };
}
const initialState: TravelRuleVASPState = {
  params: getDefaultTravelRuleVASPRequestParams(),
  TravelRuleVASP: {
    status: RequestStatus.Idle,
  },
  TravelRuleVASPPaginationConfig: {
    status: RequestStatus.Idle,
  },
  canLoadMore: true,
  paramsCompany: {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: Sort.CUSTOMER_NAME,
    [Params.Sort]: SortDirection.ASC,
    [Params.SearchOnScreen]: CustomerOnScreen.TRAVELRULEVASP,
  },
  company: {
    status: RequestStatus.Idle,
  },
  canLoadMoreCompany: true,
  usersByCompany: {
    status: RequestStatus.Idle,
  },
  paramsUsersByCompany: getDefaultUsersParams(),
  canLoadMoreUsers: true,
  category: {
    status: RequestStatus.Idle,
  },
  paramsCategory: getDefaultCategoryGroupByRequestParams(),
  type: {
    status: RequestStatus.Idle,
  },
  paramsType: getDefaultTypeGroupByRequestParams(),
  action: {
    status: RequestStatus.Idle,
  },
  paramsAction: getDefaultActionGroupByRequestParams(),
  TravelRuleVASPDetail: {
    status: RequestStatus.Idle,
  },
};
const getTravelRuleVASPRequest: CaseReducer<TravelRuleVASPState, PayloadAction<any>> = (
  state: TravelRuleVASPState,
) => {
  state.TravelRuleVASP.status = RequestStatus.Loading;
};
const getTravelRuleVASPSuccess: CaseReducer<
  TravelRuleVASPState,
  PayloadAction<GetTravelRuleVASPResponseDTO>
> = (state: TravelRuleVASPState, { payload }: PayloadAction<GetTravelRuleVASPResponseDTO>) => {
  state.TravelRuleVASP.status = RequestStatus.Success;
  state.TravelRuleVASP.data = payload;
};
const getTravelRuleVASPFailed: CaseReducer<TravelRuleVASPState> = (state: TravelRuleVASPState) => {
  state.TravelRuleVASP.status = RequestStatus.Failed;
};
const getDetailTravelRuleVASPRequest: CaseReducer<TravelRuleVASPState, PayloadAction<string>> = (
  state: TravelRuleVASPState,
) => {
  state.TravelRuleVASPDetail.status = RequestStatus.Loading;
};
const getDetailTravelRuleVASPSuccess: CaseReducer<
  TravelRuleVASPState,
  PayloadAction<ITravelRuleVASPDetail>
> = (state: TravelRuleVASPState, { payload }: PayloadAction<ITravelRuleVASPDetail>) => {
  state.TravelRuleVASPDetail.status = RequestStatus.Success;
  state.TravelRuleVASPDetail.data = payload;
};
const getDetailTravelRuleVASPFailed: CaseReducer<TravelRuleVASPState> = (state: TravelRuleVASPState) => {
  state.TravelRuleVASPDetail.status = RequestStatus.Failed;
};
const createTravelRuleVASPRequest: CaseReducer<TravelRuleVASPState, PayloadAction<ITravelRuleVASPDetail>> = (
  state: TravelRuleVASPState,
) => {
  state.TravelRuleVASPDetail.status = RequestStatus.Loading;
};
const createTravelRuleVASPSuccess: CaseReducer<TravelRuleVASPState, PayloadAction<ITravelRuleVASPDetail>> = (
  state: TravelRuleVASPState,
  { payload }: PayloadAction<ITravelRuleVASPDetail>,
) => {
  state.TravelRuleVASPDetail.status = RequestStatus.Success;
  state.TravelRuleVASPDetail.data = payload;
};
const createTravelRuleVASPFailed: CaseReducer<TravelRuleVASPState> = (state: TravelRuleVASPState) => {
  state.TravelRuleVASPDetail.status = RequestStatus.Failed;
};
const updateTravelRuleVASPRequest: CaseReducer<TravelRuleVASPState, PayloadAction<ITravelRuleVASPDetail>> = (
  state: TravelRuleVASPState,
) => {
  state.TravelRuleVASPDetail.status = RequestStatus.Loading;
};
const updateTravelRuleVASPSuccess: CaseReducer<TravelRuleVASPState, PayloadAction<ITravelRuleVASPDetail>> = (
  state: TravelRuleVASPState,
  { payload }: PayloadAction<ITravelRuleVASPDetail>,
) => {
  state.TravelRuleVASPDetail.status = RequestStatus.Success;
  state.TravelRuleVASPDetail.data = payload;
};
const updateTravelRuleVASPFailed: CaseReducer<TravelRuleVASPState> = (state: TravelRuleVASPState) => {
  state.TravelRuleVASPDetail.status = RequestStatus.Failed;
};
const appendTravelRuleVASP: CaseReducer<TravelRuleVASPState, PayloadAction<any>> = (
  state: TravelRuleVASPState,
  { payload }: PayloadAction<any>,
) => {
  if (state.TravelRuleVASP?.data?.listTravelRuleVASP) {
    state.TravelRuleVASP.status = RequestStatus.Success;
    state.TravelRuleVASP.data.listTravelRuleVASP = [...payload];
  }
};
const setCanLoadMore = (state: TravelRuleVASPState, { payload }: PayloadAction<any>) => {
  state.canLoadMore = payload;
};
const resetTravelRuleVASP: CaseReducer<TravelRuleVASPState> = (state: TravelRuleVASPState) => {
  state.TravelRuleVASP = {
    status: RequestStatus.Idle,
  };
};
const resetTravelRuleVASPParams: CaseReducer<TravelRuleVASPState> = (state: TravelRuleVASPState) => {
  state.params = getDefaultTravelRuleVASPRequestParams();
};
const setTravelRuleVASPFilterParams = (
  state: TravelRuleVASPState,
  { payload }: PayloadAction<GetTravelRuleVASPRequestParamDTO>,
) => {
  state.params = payload;
};
// TODO: Action
const fetchActionRequest: CaseReducer<
  TravelRuleVASPState,
  PayloadAction<CategoryGroupByRequestParamsDTO>
> = (state: TravelRuleVASPState) => {
  state.action.status = RequestStatus.Loading;
};
const fetchActionSuccess: CaseReducer<TravelRuleVASPState, PayloadAction<any>> = (
  state: TravelRuleVASPState,
  { payload }: PayloadAction<any>,
) => {
  state.action.status = RequestStatus.Success;
  state.action.data = payload;
};
const fetchActionFailed: CaseReducer<TravelRuleVASPState> = (state: TravelRuleVASPState) => {
  state.action.status = RequestStatus.Failed;
};
const getTravelRuleVASPPaginationConfigRequest: CaseReducer<
  TravelRuleVASPState,
  PayloadAction<any>
> = (state: TravelRuleVASPState) => {
  state.TravelRuleVASPPaginationConfig.status = RequestStatus.Loading;
};
const getTravelRuleVASPPaginationConfigSuccess: CaseReducer<
  TravelRuleVASPState,
  PayloadAction<IRakCRUDBaseControllerConfig>
> = (
  state: TravelRuleVASPState,
  { payload }: PayloadAction<IRakCRUDBaseControllerConfig>,
) => {
  state.TravelRuleVASPPaginationConfig.status = RequestStatus.Success;
  state.TravelRuleVASPPaginationConfig.data = payload;
};
const getTravelRuleVASPPaginationConfigFailed: CaseReducer<TravelRuleVASPState> = (
  state: TravelRuleVASPState,
) => {
  state.TravelRuleVASPPaginationConfig.status = RequestStatus.Failed;
};
const TravelRuleVASPSlice = createSlice({
  name: 'TravelRuleVASP',
  initialState,
  reducers: {
    getTravelRuleVASPRequest,
    getTravelRuleVASPSuccess,
    getTravelRuleVASPFailed,
    getDetailTravelRuleVASPRequest,
    getDetailTravelRuleVASPSuccess,
    getDetailTravelRuleVASPFailed,
    createTravelRuleVASPRequest,
    createTravelRuleVASPSuccess,
    createTravelRuleVASPFailed,
    updateTravelRuleVASPRequest,
    updateTravelRuleVASPSuccess,
    updateTravelRuleVASPFailed,
    appendTravelRuleVASP,
    setCanLoadMore,
    resetTravelRuleVASP,
    setTravelRuleVASPParams<K extends keyof GetTravelRuleVASPRequestParamDTO>(
      state: TravelRuleVASPState,
      action: PayloadAction<{
        key: K;
        value: GetTravelRuleVASPRequestParamDTO[K];
      }>,
    ) {
      state.params[action.payload.key] = action.payload.value;
      if (action.payload.key === Params.KeyWord) {
        state.params[Params.KeyWord.toString()] = action.payload.value;
      }
    },
    setTravelRuleVASPFilterParams,
    resetTravelRuleVASPParams,
    // Action
    fetchActionRequest,
    fetchActionSuccess,
    fetchActionFailed,
    // pagination
    getTravelRuleVASPPaginationConfigRequest,
    getTravelRuleVASPPaginationConfigSuccess,
    getTravelRuleVASPPaginationConfigFailed,
  },
});
// Actions
export const TravelRuleVASPActions = TravelRuleVASPSlice.actions;
// Reducer
export const TravelRuleVASPReducer = TravelRuleVASPSlice.reducer;
// Selectors
export const selectTravelRuleVASP = (state: AppState) => state.TravelRuleVASP?.TravelRuleVASP;
export const selectParamTravelRuleVASP = (state: AppState) => state.TravelRuleVASP?.params;
export const selectCanLoadMoreTravelRuleVASP = (state: AppState) =>
  state.TravelRuleVASP?.canLoadMore;
export const selectTravelRuleVASPDetail = (state: AppState) => state.TravelRuleVASP?.TravelRuleVASPDetail;
export const selectTravelRuleVASPPagination = (state: AppState) =>
  state.TravelRuleVASP?.TravelRuleVASPPaginationConfig;
