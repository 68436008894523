import { Col, Row } from 'antd';
import { SvgIcon } from 'app/components/SvgIcon';
import { useSelector } from 'react-redux';
import styles from './index.module.scss';
import { selectCfg_country } from 'store/slices/cfg_country/cfg_country.slice';
import { useAppSelector } from 'store/hooks';

type Props = {
  customer: any;
};

interface ITextInfor {
  label: any;
  text: any;
  span?: number;
  dataTestId?: string;
}

const OriginatorInformation = (props: Props) => {
  const countryList =
    useAppSelector(selectCfg_country)?.data?.listCfg_country ?? [];

  const countryName = id =>
    countryList.find(
      i => (i.id || '').toLowerCase() === (id || '').toLowerCase(),
    )?.name ?? id;

  const formatText = (text: string) => {
    let result = '-';

    if (text) {
      result = text.toString().toLowerCase();
      result = result.charAt(0).toUpperCase() + result.slice(1);
    }
    return result;
  };

  const TextInfor = ({ span = 12, label, text, dataTestId }: ITextInfor) => {
    return (
      <Col span={span}>
        <div className={styles.content__item}>
          <p className={styles.content__label}>{label}</p>
          <p className={styles.content__text} data-testid={dataTestId}>
            {text}
          </p>
        </div>
      </Col>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.info}>
          <div className={styles.icon}>
            <SvgIcon name="info-red" width={16} height={16} />
          </div>
          <div className={styles.title}>Originator Information</div>
        </div>
      </div>
      <Row style={{ marginBottom: 20 }}>
        <TextInfor
          label="Name"
          dataTestId="name"
          text={formatText(props.customer?.customerName || '')}
        />
        <TextInfor
          label="Registered business no. "
          dataTestId="registered-business-no"
          text={formatText(props.customer?.businessRegistrationId || '')}
        />
      </Row>
      <Row style={{ marginBottom: 20 }}>
        <TextInfor
          label="Registered business address"
          dataTestId="registered-business-address"
          text={formatText(props.customer?.registrationAddress?.address || '')}
        />
        <TextInfor
          label="Postcode"
          dataTestId="postcode"
          text={formatText(
            props.customer?.registrationAddress?.postCode ||
              props.customer?.registrationAddress?.postcode ||
              '',
          )}
        />
      </Row>
      <Row>
        <TextInfor
          label="Country"
          dataTestId="country"
          text={formatText(
            countryName(props.customer?.countryCodeRegistration) || '',
          )}
        />
      </Row>
    </div>
  );
};

export default OriginatorInformation;
