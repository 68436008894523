import { PREFIX_API } from 'constants/index';
import {
  TransactionChecklist,
  TransactionTierSigner,
} from 'interfaces/transaction';
import Instance from '../app.v2/config/instance';

export function getTransactionDetailByTxnId(txId: string): Promise<any> {
  return Instance.get(`/${PREFIX_API.TRANSACTION}/transactions/${txId}`);
}

export function getTransactionSourceAndDestinationData(
  externalExchangeAccountId: string,
  type: string,
  tokenId: string,
  workspaceId?: string,
): Promise<any> {
  return Instance.get(
    `/${PREFIX_API.TRANSACTION}/transactions/source-destination?externalExchangeAccountId=${externalExchangeAccountId}&type=${type}&tokenId=${tokenId}&workspaceId=${workspaceId}`,
  );
}

export function markTransactionAsReview(txId: string, body): Promise<any> {
  return Instance.post(
    `/${PREFIX_API.TRANSACTION}/transactions/${txId}/review`,
    body,
  );
}

export function getReviewerByTxnId(txId: string): Promise<any> {
  return Instance.get(`/${PREFIX_API.TRANSACTION}/transactions/${txId}/review`);
}

export function getReviewChecklistByTxnId(
  txId: string,
): Promise<TransactionChecklist> {
  return Instance.get(
    `/${PREFIX_API.TRANSACTION}/transactions/${txId}/review/checklist`,
  );
}

export function unFreezeTransaction(txId: string): Promise<any> {
  return Instance.post(`/${PREFIX_API.TRANSACTION}/transactions/unfreeze`, {
    txId,
  });
}

export function rejectUnfreezeTransaction(payload: any): Promise<any> {
  return Instance.post(
    `/${PREFIX_API.TRANSACTION}/transactions/reject-unfreeze`,
    payload,
  );
}

export function getTransactionTier(): Promise<TransactionTierSigner[]> {
  return Instance.get(`/${PREFIX_API.TRANSACTION}/transactions/tiers-signer`);
}

export function resendFbWebhookTxnsAPI(body?: any): Promise<any> {
  return Instance.patch(
    `/${PREFIX_API.TRANSACTION}/transactions/resend-webhook-by-ids`,
    body,
  );
}

export function getVaspDetail(params: string): Promise<any> {
  return Instance.get(`/core/v2/rep/TravelRule/VASP/${params}`);
}
