import { DatePicker, Form } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { FORMAT_DATE } from 'constants/index';
import { CommonStatus } from 'enum/common';
import { SvgIcon } from 'app/components/common/SvgIcon';
import { Params, RequestStatus } from 'interfaces/request';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  BatchElvenTxnRAKOnChainActions,
  selectParamBatchElvenTxnRAKOnChain,
  selectBatchElvenTxnRAKOnChainPagination,
} from 'store/slices/BatchElvenTxnRAKOnChain/BatchElvenTxnRAKOnChain.slice';
import BasicButton from '../common/BasicButton';
import SelectInfiniteScroll from '../common/SelectInfiniteScroll';
import { FormItem } from '../common/SelectInfiniteScroll/Styles';
import styles from './BatchElvenTxnRAKOnChainFilter.module.scss';
import DynamicFilter from '../DynamicFilter';
import { GetBatchElvenTxnRAKOnChainRequestParamDTO } from 'interfaces/BatchElvenTxnRAKOnChain';
const { RangePicker } = DatePicker;
const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;
const BoxTop = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
`;
const BoxBottom = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 32px;
`;
const FormItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;
const ClearAllButton = styled(BasicButton)`
  cursor: pointer;
  height: 36px;
  padding: 0 16px;
  background: #ffffff;
  border: 1px solid #d5d7df;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 24px;
  color: #2c303c;
  margin-right: 8px;
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ApplyButton = styled(BasicButton)`
  cursor: pointer;
  /* padding: 10px 16px; */
  background: #25397c;
  border-radius: 24px;
  color: #ffffff;
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
`;
const StyledForm = styled(Form)`
  padding: 16px 16px 0;
  background: #ffffff;
  border: 1px solid #ebecf0;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: auto;
`;
const StyledRangePicker = styled(RangePicker)`
  width: 100%;
`;
const disabledDate: RangePickerProps['disabledDate'] = current => {
  return current && current.isAfter(moment().subtract(0, 'day'));
};
const statusOptions = [
  {
    label: 'ACTIVE',
    value: CommonStatus.ACTIVE,
  },
  {
    label: 'DEACTIVE',
    value: CommonStatus.DEACTIVE,
  },
];
interface Props {
  FilterButton: () => JSX.Element;
  onInputSearch: (value: string) => void;
  keyword?: string;
  onRefresh: () => void;
  onDownload: () => void;
  onFinishFilter: (values: any) => void;
  isFilterShown: boolean;
  isMaskBatchElvenTxnRAKOnChain: boolean;
  setIsMaskBatchElvenTxnRAKOnChain: (value: boolean) => void;
}
const defaultFormFilter = {
  company: undefined,
  username: undefined,
  category: undefined,
  type: undefined,
  action: undefined,
  status: undefined,
  activityDate: [null, null],
};
const BatchElvenTxnRAKOnChainFilter: React.FunctionComponent<Props> = ({
  onInputSearch,
  onFinishFilter,
  isFilterShown,
  keyword,
}) => {
  const dispatch = useAppDispatch();
  const { action } = useAppSelector(state => state.BatchElvenTxnRAKOnChain);
  const [actions, setActions] = useState<any>([]);
  const [isDisabledClearAll, setIsDisabledClearAll] = useState(true);
  const params = useAppSelector(selectParamBatchElvenTxnRAKOnChain);
  const BatchElvenTxnRAKOnChainPagination = useAppSelector(selectBatchElvenTxnRAKOnChainPagination);
  const [form] = Form.useForm();
  const typeValue = Form.useWatch('type', form);
  useEffect(() => {
    if (action?.data) {
      setActions(action?.data);
    }
  }, [action]);
  const onClearAll = () => {
    form.resetFields();
    dispatch(BatchElvenTxnRAKOnChainActions.resetBatchElvenTxnRAKOnChain());
    setIsDisabledClearAll(true);
  };
  const handleFieldsChange = useCallback((_, values) => {
    const hasSomeValue = hasSomeParameters(values);
    setIsDisabledClearAll(!hasSomeValue);
  }, []);
  const hasSomeParameters = (values: any) => {
    const { action, activityDate, category, company, status, type, username } =
      values;
    const hasAction = action?.length;
    const hasCategory = category?.length;
    const hasCompany = company?.length;
    const hasStatus = status?.length;
    const hasType = type?.length;
    const hasUsername = username?.length;
    const hasActivitiDate = activityDate?.some(Boolean);
    return (
      hasAction ||
      hasCategory ||
      hasCompany ||
      hasStatus ||
      hasType ||
      hasUsername ||
      hasActivitiDate
    );
  };
  const onFinish = (values: any) => {
    onFinishFilter(values);
  };
  const setValue = (key: keyof GetBatchElvenTxnRAKOnChainRequestParamDTO) => {
    return (value: GetBatchElvenTxnRAKOnChainRequestParamDTO[keyof GetBatchElvenTxnRAKOnChainRequestParamDTO]) => {
      dispatch(
        BatchElvenTxnRAKOnChainActions.setBatchElvenTxnRAKOnChainParams({
          key,
          value,
        }),
      );
    };
  };
  const setWhere = setValue(Params.Where),
    setOrder = setValue(Params.Order),
    setOffset = setValue(Params.Offset);
  return (
    <div id="BatchElvenTxnRAKOnChainFilter">
      <TopContainer>
        <DynamicFilter
          {...{
            setParams: ({ where, order }) => {
              dispatch(BatchElvenTxnRAKOnChainActions.resetBatchElvenTxnRAKOnChain());
              setOffset(0);
              setWhere(where);
              setOrder(order);
            },
            where: params?.where,
            order: params?.order,
            paginationConfig: BatchElvenTxnRAKOnChainPagination,
          }}
        />
      </TopContainer>
      {isFilterShown && (
        <StyledForm
          form={form}
          initialValues={defaultFormFilter}
          layout="vertical"
          name="requestFilter"
          onValuesChange={handleFieldsChange}
          onFinish={onFinish}
        >
          <BoxTop>
            <FormItem label={<span>Activity date</span>} name={'activityDate'}>
              <StyledRangePicker
                separator={
                  <SvgIcon name="arrow-right" width={16} height={16} />
                }
                placeholder={['From', 'To']}
                allowEmpty={[true, true]}
                disabledDate={disabledDate}
                format={FORMAT_DATE.DEFAULT}
                className={styles.datePicker}
                data-testid="activityDate"
              />
            </FormItem>
          </BoxTop>
          <BoxBottom>
            <BoxTop>
              <SelectInfiniteScroll
                items={actions}
                status={action.status || RequestStatus.Idle}
                name={'action'}
                dataTestId={'action'}
                label="Action"
                placeholder="Select action"
                showSearch={false}
                labelValue="name"
                keyValue="id"
                labelGroup="label"
                keyGroup="options"
                disabled={!typeValue || typeValue?.length <= 0}
              />
              <SelectInfiniteScroll
                items={statusOptions}
                status={RequestStatus.Success}
                name={'status'}
                dataTestId={'status'}
                label="Status"
                placeholder="Select status"
                showSearch={false}
                labelValue="label"
                keyValue="value"
              />
            </BoxTop>
            <FormItems>
              <ClearAllButton
                title="Clear all"
                type="outline"
                dataTestId="btn-clear"
                disabled={isDisabledClearAll}
                onClick={onClearAll}
              />
              <ApplyButton
                type="filled"
                title="Apply"
                dataTestId="btn-apply"
                onClick={() => {
                  form.submit();
                }}
              />
            </FormItems>
          </BoxBottom>
        </StyledForm>
      )}
    </div>
  );
};
export default BatchElvenTxnRAKOnChainFilter;
