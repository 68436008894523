import { PREFIX_API } from 'constants/index';
import Instance from '../app.v2/config/instance';
import {
  GetTravelRuleVASPRequestParamDTO,
  GetTravelRuleVASPResponseDTO,
  ICreateTravelRuleVASP,
  ITravelRuleVASPDetail,
} from 'interfaces/TravelRuleVASP';
export function getTravelRuleVASPRequest(
  params?: GetTravelRuleVASPRequestParamDTO,
): Promise<GetTravelRuleVASPResponseDTO> {
  return Instance.get(`/${PREFIX_API.TRAVELRULEVASP}`, {
    params: {
      ...params,
      where: JSON.stringify(params?.where || {}),
      order: JSON.stringify(params?.order || []),
    },
  });
}
export function getDetailTravelRuleVASP(
  TravelRuleVASPId: string,
): Promise<ITravelRuleVASPDetail> {
  return Instance.get(`/${PREFIX_API.TRAVELRULEVASP}/${ TravelRuleVASPId }`);
}
export function createTravelRuleVASPRequest(
  TravelRuleVASP: ICreateTravelRuleVASP,
): Promise<GetTravelRuleVASPResponseDTO> {
  return Instance.post(`/${PREFIX_API.TRAVELRULEVASP}`, TravelRuleVASP);
}
export function updateTravelRuleVASPRequest(
  TravelRuleVASP: ICreateTravelRuleVASP,
): Promise<GetTravelRuleVASPResponseDTO> {
  const payload: any = { ...TravelRuleVASP };
  const id = TravelRuleVASP.id;
  delete payload.id;
  return Instance.put(`/${PREFIX_API.TRAVELRULEVASP}/${id}`, payload);
}
export function getTravelRuleVASPRequestPaginationConfig(): Promise<GetTravelRuleVASPResponseDTO> {
  return Instance.get(`/${PREFIX_API.TRAVELRULEVASP}/pagination-config`);
}
