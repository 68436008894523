import { Col, Divider, Row } from 'antd';
import clsx from 'clsx';
import { useAppSelector } from 'store/hooks';
import styles from './index.module.scss';
import { selectCfg_country } from 'store/slices/cfg_country/cfg_country.slice';

type Props = {
  drawerWidth?: number;
  folder: any;
};

interface ITextInfor {
  label: any;
  text: any;
  span?: number;
  dataTestId?: string;
  isEmpty?: boolean;
  isLast?: boolean;
}

const FolderInformation = ({ drawerWidth = 665, folder }: Props) => {
  const countryList =
    useAppSelector(selectCfg_country)?.data?.listCfg_country ?? [];

  const countryName = id =>
    countryList.find(
      i => (i.id || '').toLowerCase() === (id || '').toLowerCase(),
    )?.name ?? id;

  const TextInfor = ({
    span = 12,
    label,
    text,
    dataTestId,
    isEmpty = false,
    isLast = false,
  }: ITextInfor) => {
    return (
      <Col span={span} style={{ paddingRight: 15 }}>
        <div
          className={clsx(styles.content__item, {
            [styles.content__last_item]: isLast,
          })}
        >
          <p className={styles.content__label}>{label}</p>
          <p className={styles.content__text} data-testid={dataTestId}>
            {isEmpty ? '-' : text}
          </p>
        </div>
      </Col>
    );
  };

  return (
    <div className={styles.content}>
      <Row className={styles.caption}>Folder information</Row>
      <Row style={{ marginBottom: 10 }}>
        <TextInfor
          label="Folder name"
          dataTestId="folder-name"
          text={
            <Row style={{ gap: 8 }} align="middle">
              <span className={clsx(styles.name, styles.normal__name)}>
                {folder?.name}
              </span>
            </Row>
          }
        />
        <TextInfor
          label="Beneficiary"
          dataTestId="Beneficiary"
          text={
            <Row style={{ gap: 8 }}>
              <span className={clsx(styles.name, styles.normal__name)}>
                {folder?.type === 'internal' ? 'Own' : 'Third party'}
              </span>
            </Row>
          }
        />
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <TextInfor
          label="Beneficiary name"
          dataTestId="beneficiary-name"
          text={
            <Row style={{ gap: 8 }} align="middle">
              <span className={clsx(styles.name, styles.normal__name)}>
                {folder?.businessInfo?.businessName}
              </span>
            </Row>
          }
        />
        <TextInfor
          label="Registered business address "
          dataTestId="registered-business-address "
          text={
            <Row style={{ gap: 8 }}>
              <span className={clsx(styles.name, styles.normal__name)}>
                {folder?.businessInfo?.businessAddress}
              </span>
            </Row>
          }
        />
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <TextInfor
          label="Country"
          dataTestId="country"
          text={
            <Row style={{ gap: 8 }} align="middle">
              <span className={clsx(styles.name, styles.normal__name)}>
                {countryName(folder?.businessInfo?.countryCode)}
              </span>
            </Row>
          }
        />
        <TextInfor
          label="Purpose of transfer"
          dataTestId="purpose-of-transfer"
          text={
            <Row style={{ gap: 8 }}>
              <span className={clsx(styles.name, styles.normal__name)}>
                {folder?.businessInfo?.purposeTransfer}
              </span>
            </Row>
          }
        />
      </Row>
      <Row>
        <TextInfor
          label="Relationship"
          dataTestId="relationship"
          text={
            <Row style={{ gap: 8 }} align="middle">
              <span className={clsx(styles.name, styles.normal__name)}>
                {folder?.businessInfo?.relationship}
              </span>
            </Row>
          }
        />
        <TextInfor
          label="Source of fund"
          dataTestId="source-of-fund"
          text={
            <Row style={{ gap: 8 }}>
              <span className={clsx(styles.name, styles.normal__name)}>
                {folder?.businessInfo?.sourceFunds}
              </span>
            </Row>
          }
        />
      </Row>
      <Divider orientationMargin="0" style={{ margin: '15px 0 0px' }} />
    </div>
  );
};

export default FolderInformation;
