import clsx from 'clsx';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import {
  commonAttributesColumnTable,
  PER_PAGE,
  timeZone,
} from 'constants/index';
import { PAGE } from 'enum/common';
import { Params, RequestStatus } from 'interfaces/request';
import { GetTravelRuleJurisdictionsRequestParamDTO } from 'interfaces/TravelRuleJurisdictions';
import { useAppSelector } from 'store/hooks';
import {
  TravelRuleJurisdictionsActions,
  selectTravelRuleJurisdictions,
  selectCanLoadMoreTravelRuleJurisdictions,
  selectParamTravelRuleJurisdictions,
} from 'store/slices/TravelRuleJurisdictions/TravelRuleJurisdictions.slice';
import BasicIconSort from 'app/components/common/BasicIconSort';
import { SvgIcon } from 'app/components/common/SvgIcon';
import RTable from 'app/components/Table';
import TravelRuleJurisdictionsDetail from './TravelRuleJurisdictionsDetail';
import { Row, Tooltip } from 'antd';
import { SortTravelRuleJurisdictions } from 'enum/TravelRuleJurisdictions.enum';
import styles from './TravelRuleJurisdictions.module.scss';
import RButton from '../Button';
const StyledNoSearchResults = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  .Title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #959aaa;
    margin: 24px 0 8px;
  }
  .SubTitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #959aaa;
  }
`;
const NoSearchResults = () => (
  <StyledNoSearchResults>
    <div>
      <SvgIcon name={'search-empty'} width={80} height={80} fill="#25397C" />
    </div>
    <div className="Title">No search results</div>
    <div className="SubTitle">
      <div>Your search returns no matches,</div>
      <div>please check and try again.</div>
    </div>
  </StyledNoSearchResults>
);
const renderIcon = (
  sortOrder: string,
  sortColumn: any,
  title: string,
  dataIndex: string,
  align = 'left' as string,
) => {
  return (
    <>
      <div
        className={clsx(styles.title, {
          [styles.titleHeaderRight]: align === 'right',
        })}
        data-testid={`column-${dataIndex}`}
        id={dataIndex}
      >
        <span>{title}</span>
        {sortOrder && sortColumn?.dataIndex === dataIndex && (
          <BasicIconSort sortOrder={sortOrder} />
        )}
      </div>
    </>
  );
};
interface Props {
  isFilterShown: boolean;
  isMaskTravelRuleJurisdictions: boolean;
}
const TravelRuleJurisdictions: React.FunctionComponent<Props> = ({
  isFilterShown,
  isMaskTravelRuleJurisdictions,
}) => {
  const [selectedRowId, setSelectedRowId] = useState('');
  const [open, setOpen] = useState(false);
  const [itemSelected, setItemSelected] = useState<null | undefined | unknown>(
    null,
  );
  const [TravelRuleJurisdictionss, setTravelRuleJurisdictionss] = useState<
    any[]
  >([]);
  const [firstRender, setFirstRender] = useState(true);
  const dispatch = useDispatch();
  const TravelRuleJurisdictions = useAppSelector(selectTravelRuleJurisdictions);
  const params = useAppSelector(selectParamTravelRuleJurisdictions);
  const canLoadMore = useAppSelector(selectCanLoadMoreTravelRuleJurisdictions);
  const refCanLoadMoreStore = useRef(canLoadMore);
  const loading = TravelRuleJurisdictions?.status === RequestStatus.Loading;
  const height = useMemo(() => {
    const heightFilter = isFilterShown ? 242 : 36;
    const heightPadding = 120; // both top bottom
    return window.innerHeight - (32 * 2 + 24) - heightFilter - heightPadding; //count table height
  }, [isFilterShown]);
  const setValue = (key: keyof GetTravelRuleJurisdictionsRequestParamDTO) => {
    return (
      value: GetTravelRuleJurisdictionsRequestParamDTO[keyof GetTravelRuleJurisdictionsRequestParamDTO],
    ) => {
      dispatch(
        TravelRuleJurisdictionsActions.setTravelRuleJurisdictionsParams({
          key,
          value,
        }),
      );
    };
  };
  const setSortStore = setValue(Params.Sort);
  const setSortByStore = setValue(Params.SortBy);
  const setOffset = setValue(Params.Offset);
  const setTimeZone = setValue(Params.TimeZone);
  if (-12 <= timeZone && timeZone <= 14) {
    setTimeZone(timeZone);
  }
  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    const sort =
      sorter.order === 'descend'
        ? 'DESC'
        : sorter.order === 'ascend'
        ? 'ASC'
        : undefined;
    const sortBy = SortTravelRuleJurisdictions[sorter.field];
    setOffset(0);
    setSortStore(sort);
    setSortByStore(sortBy);
  };
  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
    }
    return () => {
      if (!firstRender) {
        dispatch(
          TravelRuleJurisdictionsActions.resetTravelRuleJurisdictionsParams(),
        );
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRender]);
  useEffect(() => {
    refCanLoadMoreStore.current = canLoadMore;
  }, [canLoadMore]);
  const loadMore = useCallback(
    (event: {
      target: { scrollHeight: number; clientHeight: number; scrollTop: number };
    }) => {
      const maxScroll = event.target.scrollHeight - event.target.clientHeight;
      const currentScroll = (event.target.scrollTop + 2).toFixed();
      let offset = params?.offset || 0;
      if (maxScroll === 0 && offset > 0) {
        offset = 0;
      }
      if (
        refCanLoadMoreStore.current &&
        Number(currentScroll) > maxScroll &&
        TravelRuleJurisdictionss.length === offset + PER_PAGE
      ) {
        setOffset(offset + PER_PAGE);
      }
    },
    [TravelRuleJurisdictionss.length, params?.offset, setOffset],
  );
  useEffect(() => {
    let tableContent: any = document.querySelector('.ant-table-body');
    if (!tableContent) return;
    tableContent.addEventListener('scroll', loadMore);
    return () => tableContent.removeEventListener('scroll', loadMore);
  }, [loadMore]);
  useEffect(() => {
    let tableContent: any = document.querySelector('.ant-table-body');
    if (tableContent && params.offset === 0) {
      tableContent.scrollTop = 0;
    }
  }, [params.offset]);
  useEffect(() => {
    if (TravelRuleJurisdictions?.data?.listTravelRuleJurisdictions) {
      setTravelRuleJurisdictionss(
        TravelRuleJurisdictions.data.listTravelRuleJurisdictions,
      );
    }
  }, [TravelRuleJurisdictions]);
  useEffect(() => {
    const length = TravelRuleJurisdictionss.length || 0;
    const count = height / 60; //60 height 1 record
    let offset = Number(params?.offset) + PER_PAGE || PER_PAGE;
    const total = TravelRuleJurisdictions?.data?.total ?? 0;
    if (offset < count && offset < total && length === offset) {
      setOffset(offset);
    }
  }, [
    TravelRuleJurisdictions?.data?.total,
    TravelRuleJurisdictionss,
    height,
    params?.offset,
    setOffset,
  ]);
  const handleClickOnRow = (value: any) => {
    if (value) {
      setOpen(true);
      setItemSelected(value);
      setSelectedRowId(value.id);
    }
  };
  const NoResultComponent = useCallback(() => <NoSearchResults />, []);
  const columns: any = [
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(sortOrder, sortColumn, 'ID', 'id');
      },
      dataIndex: 'id',
      key: 'id',
      render: (_: any, item: { id: any }) => {
        return (
          <Tooltip title={item?.id} placement="bottomLeft">
            <div className={styles.id}>
              <span>{item?.id}</span>
            </div>
          </Tooltip>
        );
      },
      width: '172px',
    },
    ...([
      'id',
      // 'thresholds',
      'countryCode',
      'currency',
      'jurisdictionStatus',
      'jurisdictionNotabenePage',
      'forceFields',
      'isDeleted',
      'status',
      'createdBy',
      'updatedBy',
      'createdAt',
      'updatedAt',
      'customerId',
    ].map(key => {
      return {
        ...commonAttributesColumnTable,
        title: ({ sortOrder, sortColumn }: any) => {
          return renderIcon(sortOrder, sortColumn, key, key);
        },
        dataIndex: key,
        key: key,
        render: (_: any, item: any) => {
          return (
            <Tooltip title={item[key]} placement="bottomLeft">
              <div className={styles[key]}>
                <span>{item[key]}</span>
              </div>
            </Tooltip>
          );
        },
        width: '172px',
      };
    }) || []),
  ];
  return (
    <div className={styles.tableAudit} data-testId="table">
      Total: {TravelRuleJurisdictions?.data?.total ?? 0}
      <Row style={{ justifyContent: 'end', marginBottom: '20px' }}>
        <RButton
          buttonType="submit"
          content="Insert"
          width={75}
          height={32}
          onClick={() => {
            setItemSelected(undefined);
            setOpen(true);
            dispatch(
              TravelRuleJurisdictionsActions.getDetailTravelRuleJurisdictionsSuccess(
                {},
              ),
            );
          }}
          style={{ padding: '0' }}
        />
      </Row>
      <RTable
        currentTableData={TravelRuleJurisdictionss}
        columns={columns}
        loading={loading}
        height={height}
        selectedRowId={selectedRowId}
        clickOnRow={handleClickOnRow}
        page={PAGE.WALLET_INFO}
        onChange={onTableChange}
        NoSearchResults={NoResultComponent}
      />
      {itemSelected !== null && (
        <TravelRuleJurisdictionsDetail
          open={open}
          onClose={() => {
            setOpen(false);
            setSelectedRowId('');
            dispatch(
              TravelRuleJurisdictionsActions.getDetailTravelRuleJurisdictionsSuccess(
                {},
              ),
            );
          }}
          itemSelected={itemSelected}
        />
      )}
    </div>
  );
};
export default TravelRuleJurisdictions;
