import { PREFIX_API } from 'constants/index';
import Instance from '../app.v2/config/instance';
import {
  GetTravelRuleJurisdictionsRequestParamDTO,
  GetTravelRuleJurisdictionsResponseDTO,
  ICreateTravelRuleJurisdictions,
  ITravelRuleJurisdictionsDetail,
} from 'interfaces/TravelRuleJurisdictions';
export function getTravelRuleJurisdictionsRequest(
  params?: GetTravelRuleJurisdictionsRequestParamDTO,
): Promise<GetTravelRuleJurisdictionsResponseDTO> {
  return Instance.get(`/${PREFIX_API.TRAVELRULEJURISDICTIONS}`, {
    params: {
      ...params,
      where: JSON.stringify(params?.where || {}),
      order: JSON.stringify(params?.order || []),
    },
  });
}
export function getDetailTravelRuleJurisdictions(
  TravelRuleJurisdictionsId: string,
): Promise<ITravelRuleJurisdictionsDetail> {
  return Instance.get(
    `/${PREFIX_API.TRAVELRULEJURISDICTIONS}/${TravelRuleJurisdictionsId}`,
  );
}
export function createTravelRuleJurisdictionsRequest(
  TravelRuleJurisdictions: ICreateTravelRuleJurisdictions,
): Promise<GetTravelRuleJurisdictionsResponseDTO> {
  return Instance.post(
    `/${PREFIX_API.TRAVELRULEJURISDICTIONS}`,
    TravelRuleJurisdictions,
  );
}
export function updateTravelRuleJurisdictionsRequest(
  TravelRuleJurisdictions: ICreateTravelRuleJurisdictions,
): Promise<GetTravelRuleJurisdictionsResponseDTO> {
  const payload: any = { ...TravelRuleJurisdictions };
  const id = TravelRuleJurisdictions.id;
  delete payload.id;
  return Instance.put(`/${PREFIX_API.TRAVELRULEJURISDICTIONS}/${id}`, payload);
}
export function getTravelRuleJurisdictionsRequestPaginationConfig(): Promise<GetTravelRuleJurisdictionsResponseDTO> {
  return Instance.get(
    `/${PREFIX_API.TRAVELRULEJURISDICTIONS}/pagination-config`,
  );
}
