import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import bookOpen from 'images/book-open.svg';
import { Params } from 'interfaces/request';
import { GetTravelRuleVASPRequestParamDTO } from 'interfaces/TravelRuleVASP';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { TravelRuleVASPActions, selectParamTravelRuleVASP } from 'store/slices/TravelRuleVASP/TravelRuleVASP.slice';
import TravelRuleVASPFilter from 'app/components/TravelRuleVASP/TravelRuleVASPFilter';
import TravelRuleVASP from 'app/components/TravelRuleVASP/TravelRuleVASP';
import RButton from 'app/components/Button';
import BasicModal from 'app/components/common/BasicModal';
import { SvgIcon } from 'app/components/common/SvgIcon';
import moment from 'moment';
import { isNonEmptyObject } from 'utils/common';
import { mapRequest } from 'utils/helpers';
import styles from './TravelRuleVASP.module.scss';
const TravelRuleVASPPageContainer = styled.div``;
const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;
`;
const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: #0c0d12;
  margin-left: 12px;
`;
const FilterButton = styled.div<{ isHasFilter: boolean }>`
  cursor: pointer;
  height: 36px;
  padding: 0 16px;
  border: 1px solid #d5d7df;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 24px;
  background: ${({ isHasFilter }) => (isHasFilter ? '#ceddfb' : '#ffffff')};
  display: flex;
  flex-direction: row;
  align-items: center;
  .label {
    margin-left: 8px;
  }
`;
const TravelRuleVASPContainer = styled.div`
  margin-top: 24px;
`;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const queryParamsFilter = [
  'customerIds',
  'userIds',
  'categories',
  'types',
  'actions',
  'statuses',
  'dateFrom',
  'dateTo',
];
const TravelRuleVASPPage: React.FunctionComponent = () => {
  const [isFilterShown, setIsFilterShown] = useState(false);
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const [isHasFilter, setIsHasFilter] = useState(false);
  const [isMaskTravelRuleVASP, setIsMaskTravelRuleVASP] = useState(true);
  const dispatch = useAppDispatch();
  const params = useAppSelector(selectParamTravelRuleVASP);
  const setValue = (key: keyof GetTravelRuleVASPRequestParamDTO) => {
    return (value: GetTravelRuleVASPRequestParamDTO[keyof GetTravelRuleVASPRequestParamDTO]) => {
      dispatch(
        TravelRuleVASPActions.setTravelRuleVASPParams({
          key,
          value,
        }),
      );
    };
  };
  const setKeyword = t => {
    setValue(Params.KeyWord)(t);
    setValue(Params.Search)(t);
  };
  const setOffset = setValue(Params.Offset);
  useEffect(() => {
    return () => {
      dispatch(TravelRuleVASPActions.resetTravelRuleVASP());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onRefresh = () => {
    setOffset(0);
    dispatch(TravelRuleVASPActions.getTravelRuleVASPRequest(params));
    dispatch(TravelRuleVASPActions.getTravelRuleVASPPaginationConfigRequest(params));
  };
  const onInputSearch = (keyword: string) => {
    dispatch(TravelRuleVASPActions.resetTravelRuleVASP());
    setOffset(0);
    setKeyword(keyword);
  };
  const onDownloadClick = () => setOpenDownloadModal(true);
  const onFinishFilter = (values: any) => {
    const { action, activityDate, category, company, status, type, username } =
      values;
    const paramsFilter = {
      customerIds: !!company ? company.toString() : undefined,
      userIds: !!username ? username.toString() : undefined,
      categories: !!category ? category.toString() : undefined,
      types: !!type ? type.toString() : undefined,
      actions: !!action ? action.toString() : undefined,
      statuses: !!status ? status.toString() : undefined,
      dateFrom:
        activityDate && activityDate?.[0]
          ? moment(activityDate[0]).startOf('day').toISOString()
          : undefined,
      dateTo:
        activityDate && activityDate?.[1]
          ? moment(activityDate[1]).endOf('day').toISOString()
          : undefined,
    };
    dispatch(
      TravelRuleVASPActions.setTravelRuleVASPFilterParams({
        ...mapRequest(paramsFilter),
        offset: 0,
        limit: 10,
        keyword: params?.keyword,
        searchText: params?.keyword,
        sort: params?.sort,
        sortBy: params?.sortBy,
        isAllRequest: false,
      }),
    );
    const isHasParamsFilter = isNonEmptyObject(paramsFilter);
    setIsHasFilter(isHasParamsFilter);
  };
  useEffect(() => {
    dispatch(TravelRuleVASPActions.getTravelRuleVASPRequest(params));
    dispatch(TravelRuleVASPActions.getTravelRuleVASPPaginationConfigRequest(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);
  const FilterComponent = useCallback(
    () => (
      <FilterButton
        isHasFilter={isHasFilter}
        onClick={() => setIsFilterShown(prevState => !prevState)}
      >
        <SvgIcon
          name={`chevron-${isFilterShown ? 'up' : 'down'}`}
          width={16}
          height={16}
          fill="#64697B"
        />
        <div className="label">{`${
          isFilterShown ? 'Hide' : 'Show'
        } filter`}</div>
      </FilterButton>
    ),
    [isFilterShown, isHasFilter],
  );
  return (
    <TravelRuleVASPPageContainer>
      <TitleContainer>
        <img src={bookOpen} alt="" />
        <Title>TravelRuleVASP</Title>
      </TitleContainer>
      <TravelRuleVASPFilter
        keyword={params.keyword}
        onInputSearch={onInputSearch}
        onRefresh={onRefresh}
        onDownload={onDownloadClick}
        onFinishFilter={onFinishFilter}
        isFilterShown={isFilterShown}
        FilterButton={FilterComponent}
        isMaskTravelRuleVASP={isMaskTravelRuleVASP}
        setIsMaskTravelRuleVASP={setIsMaskTravelRuleVASP}
      />
      <TravelRuleVASPContainer>
        <TravelRuleVASP isFilterShown={isFilterShown} isMaskTravelRuleVASP={isMaskTravelRuleVASP} />
      </TravelRuleVASPContainer>
      <BasicModal
        destroyOnClose={true}
        closable={false}
        visible={openDownloadModal}
        maskClosable={true}
        width={408}
        centered={true}
        key={'TravelRuleVASP'}
        title="Available to export up to 1,000 logs"
        children={<div>Do you want to export?</div>}
        // className="exportBasicModal"
        className={styles['exportBasicModal']}
        onCancel={() => setOpenDownloadModal(false)}
        footer={[
          <RButton
            data-testid="cancel-button"
            buttonType="cancel"
            content="Cancel"
            onClick={() => setOpenDownloadModal(false)}
            width={79}
            height={36}
            style={{ marginRight: 4 }}
            key={'cancel-button'}
          />,
        ]}
      />
    </TravelRuleVASPPageContainer>
  );
};
export default TravelRuleVASPPage;
