import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Params } from 'interfaces/request';
import { PER_PAGE } from 'constants/index';
import { Sort, SortDirection } from 'enum/common';
import { SortTravelRuleJurisdictions, GroupByField, CustomerOnScreen } from 'enum/TravelRuleJurisdictions.enum';
import { RequestStatus } from 'interfaces/request';
import { ResponseData } from 'interfaces/response';
import {
  CategoryGroupByRequestParamsDTO,
  GetTravelRuleJurisdictionsRequestParamDTO,
  GetTravelRuleJurisdictionsResponseDTO,
  GetCategoryDTO,
  GetCompanyListDTO,
  GetCompanyListRequestParamsDTO,
  GroupByUserRequestParamsDTO,
  ITravelRuleJurisdictionsDetail,
} from 'interfaces/TravelRuleJurisdictions';
import { AppState } from '../..';
import { IRakCRUDBaseControllerConfig } from 'interfaces/dynamicFilter';
interface TravelRuleJurisdictionsState {
  params: GetTravelRuleJurisdictionsRequestParamDTO;
  TravelRuleJurisdictions: ResponseData<GetTravelRuleJurisdictionsResponseDTO>;
  TravelRuleJurisdictionsPaginationConfig: ResponseData<IRakCRUDBaseControllerConfig>;
  canLoadMore: boolean;
  paramsCompany: GetCompanyListRequestParamsDTO;
  company: ResponseData<GetCompanyListDTO>;
  canLoadMoreCompany: boolean;
  usersByCompany: ResponseData<any>;
  paramsUsersByCompany: GroupByUserRequestParamsDTO;
  canLoadMoreUsers: boolean;
  category: ResponseData<GetCategoryDTO>;
  paramsCategory: CategoryGroupByRequestParamsDTO;
  type: ResponseData<any>;
  paramsType: CategoryGroupByRequestParamsDTO;
  action: ResponseData<any>;
  paramsAction: CategoryGroupByRequestParamsDTO;
  TravelRuleJurisdictionsDetail: ResponseData<ITravelRuleJurisdictionsDetail>;
}
export function getDefaultTravelRuleJurisdictionsRequestParams() {
  return {
    [Params.Offset]: 0,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: SortTravelRuleJurisdictions.createdAt,
    [Params.Sort]: SortDirection.DESC,
    [Params.isAllRequest]: false,
  };
}
export function getDefaultCompanyRequestParams() {}
export function getDefaultCategoryGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultTypeGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultActionGroupByRequestParams() {
  return {
    groupBy: GroupByField.TYPE,
  };
}
export function getDefaultUsersParams() {
  return {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    customerIds: '',
  };
}
const initialState: TravelRuleJurisdictionsState = {
  params: getDefaultTravelRuleJurisdictionsRequestParams(),
  TravelRuleJurisdictions: {
    status: RequestStatus.Idle,
  },
  TravelRuleJurisdictionsPaginationConfig: {
    status: RequestStatus.Idle,
  },
  canLoadMore: true,
  paramsCompany: {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: Sort.CUSTOMER_NAME,
    [Params.Sort]: SortDirection.ASC,
    [Params.SearchOnScreen]: CustomerOnScreen.TRAVELRULEJURISDICTIONS,
  },
  company: {
    status: RequestStatus.Idle,
  },
  canLoadMoreCompany: true,
  usersByCompany: {
    status: RequestStatus.Idle,
  },
  paramsUsersByCompany: getDefaultUsersParams(),
  canLoadMoreUsers: true,
  category: {
    status: RequestStatus.Idle,
  },
  paramsCategory: getDefaultCategoryGroupByRequestParams(),
  type: {
    status: RequestStatus.Idle,
  },
  paramsType: getDefaultTypeGroupByRequestParams(),
  action: {
    status: RequestStatus.Idle,
  },
  paramsAction: getDefaultActionGroupByRequestParams(),
  TravelRuleJurisdictionsDetail: {
    status: RequestStatus.Idle,
  },
};
const getTravelRuleJurisdictionsRequest: CaseReducer<TravelRuleJurisdictionsState, PayloadAction<any>> = (
  state: TravelRuleJurisdictionsState,
) => {
  state.TravelRuleJurisdictions.status = RequestStatus.Loading;
};
const getTravelRuleJurisdictionsSuccess: CaseReducer<
  TravelRuleJurisdictionsState,
  PayloadAction<GetTravelRuleJurisdictionsResponseDTO>
> = (state: TravelRuleJurisdictionsState, { payload }: PayloadAction<GetTravelRuleJurisdictionsResponseDTO>) => {
  state.TravelRuleJurisdictions.status = RequestStatus.Success;
  state.TravelRuleJurisdictions.data = payload;
};
const getTravelRuleJurisdictionsFailed: CaseReducer<TravelRuleJurisdictionsState> = (state: TravelRuleJurisdictionsState) => {
  state.TravelRuleJurisdictions.status = RequestStatus.Failed;
};
const getDetailTravelRuleJurisdictionsRequest: CaseReducer<TravelRuleJurisdictionsState, PayloadAction<string>> = (
  state: TravelRuleJurisdictionsState,
) => {
  state.TravelRuleJurisdictionsDetail.status = RequestStatus.Loading;
};
const getDetailTravelRuleJurisdictionsSuccess: CaseReducer<
  TravelRuleJurisdictionsState,
  PayloadAction<ITravelRuleJurisdictionsDetail>
> = (state: TravelRuleJurisdictionsState, { payload }: PayloadAction<ITravelRuleJurisdictionsDetail>) => {
  state.TravelRuleJurisdictionsDetail.status = RequestStatus.Success;
  state.TravelRuleJurisdictionsDetail.data = payload;
};
const getDetailTravelRuleJurisdictionsFailed: CaseReducer<TravelRuleJurisdictionsState> = (state: TravelRuleJurisdictionsState) => {
  state.TravelRuleJurisdictionsDetail.status = RequestStatus.Failed;
};
const createTravelRuleJurisdictionsRequest: CaseReducer<TravelRuleJurisdictionsState, PayloadAction<ITravelRuleJurisdictionsDetail>> = (
  state: TravelRuleJurisdictionsState,
) => {
  state.TravelRuleJurisdictionsDetail.status = RequestStatus.Loading;
};
const createTravelRuleJurisdictionsSuccess: CaseReducer<TravelRuleJurisdictionsState, PayloadAction<ITravelRuleJurisdictionsDetail>> = (
  state: TravelRuleJurisdictionsState,
  { payload }: PayloadAction<ITravelRuleJurisdictionsDetail>,
) => {
  state.TravelRuleJurisdictionsDetail.status = RequestStatus.Success;
  state.TravelRuleJurisdictionsDetail.data = payload;
};
const createTravelRuleJurisdictionsFailed: CaseReducer<TravelRuleJurisdictionsState> = (state: TravelRuleJurisdictionsState) => {
  state.TravelRuleJurisdictionsDetail.status = RequestStatus.Failed;
};
const updateTravelRuleJurisdictionsRequest: CaseReducer<TravelRuleJurisdictionsState, PayloadAction<ITravelRuleJurisdictionsDetail>> = (
  state: TravelRuleJurisdictionsState,
) => {
  state.TravelRuleJurisdictionsDetail.status = RequestStatus.Loading;
};
const updateTravelRuleJurisdictionsSuccess: CaseReducer<TravelRuleJurisdictionsState, PayloadAction<ITravelRuleJurisdictionsDetail>> = (
  state: TravelRuleJurisdictionsState,
  { payload }: PayloadAction<ITravelRuleJurisdictionsDetail>,
) => {
  state.TravelRuleJurisdictionsDetail.status = RequestStatus.Success;
  state.TravelRuleJurisdictionsDetail.data = payload;
};
const updateTravelRuleJurisdictionsFailed: CaseReducer<TravelRuleJurisdictionsState> = (state: TravelRuleJurisdictionsState) => {
  state.TravelRuleJurisdictionsDetail.status = RequestStatus.Failed;
};
const appendTravelRuleJurisdictions: CaseReducer<TravelRuleJurisdictionsState, PayloadAction<any>> = (
  state: TravelRuleJurisdictionsState,
  { payload }: PayloadAction<any>,
) => {
  if (state.TravelRuleJurisdictions?.data?.listTravelRuleJurisdictions) {
    state.TravelRuleJurisdictions.status = RequestStatus.Success;
    state.TravelRuleJurisdictions.data.listTravelRuleJurisdictions = [...payload];
  }
};
const setCanLoadMore = (state: TravelRuleJurisdictionsState, { payload }: PayloadAction<any>) => {
  state.canLoadMore = payload;
};
const resetTravelRuleJurisdictions: CaseReducer<TravelRuleJurisdictionsState> = (state: TravelRuleJurisdictionsState) => {
  state.TravelRuleJurisdictions = {
    status: RequestStatus.Idle,
  };
};
const resetTravelRuleJurisdictionsParams: CaseReducer<TravelRuleJurisdictionsState> = (state: TravelRuleJurisdictionsState) => {
  state.params = getDefaultTravelRuleJurisdictionsRequestParams();
};
const setTravelRuleJurisdictionsFilterParams = (
  state: TravelRuleJurisdictionsState,
  { payload }: PayloadAction<GetTravelRuleJurisdictionsRequestParamDTO>,
) => {
  state.params = payload;
};
// TODO: Action
const fetchActionRequest: CaseReducer<
  TravelRuleJurisdictionsState,
  PayloadAction<CategoryGroupByRequestParamsDTO>
> = (state: TravelRuleJurisdictionsState) => {
  state.action.status = RequestStatus.Loading;
};
const fetchActionSuccess: CaseReducer<TravelRuleJurisdictionsState, PayloadAction<any>> = (
  state: TravelRuleJurisdictionsState,
  { payload }: PayloadAction<any>,
) => {
  state.action.status = RequestStatus.Success;
  state.action.data = payload;
};
const fetchActionFailed: CaseReducer<TravelRuleJurisdictionsState> = (state: TravelRuleJurisdictionsState) => {
  state.action.status = RequestStatus.Failed;
};
const getTravelRuleJurisdictionsPaginationConfigRequest: CaseReducer<
  TravelRuleJurisdictionsState,
  PayloadAction<any>
> = (state: TravelRuleJurisdictionsState) => {
  state.TravelRuleJurisdictionsPaginationConfig.status = RequestStatus.Loading;
};
const getTravelRuleJurisdictionsPaginationConfigSuccess: CaseReducer<
  TravelRuleJurisdictionsState,
  PayloadAction<IRakCRUDBaseControllerConfig>
> = (
  state: TravelRuleJurisdictionsState,
  { payload }: PayloadAction<IRakCRUDBaseControllerConfig>,
) => {
  state.TravelRuleJurisdictionsPaginationConfig.status = RequestStatus.Success;
  state.TravelRuleJurisdictionsPaginationConfig.data = payload;
};
const getTravelRuleJurisdictionsPaginationConfigFailed: CaseReducer<TravelRuleJurisdictionsState> = (
  state: TravelRuleJurisdictionsState,
) => {
  state.TravelRuleJurisdictionsPaginationConfig.status = RequestStatus.Failed;
};
const TravelRuleJurisdictionsSlice = createSlice({
  name: 'TravelRuleJurisdictions',
  initialState,
  reducers: {
    getTravelRuleJurisdictionsRequest,
    getTravelRuleJurisdictionsSuccess,
    getTravelRuleJurisdictionsFailed,
    getDetailTravelRuleJurisdictionsRequest,
    getDetailTravelRuleJurisdictionsSuccess,
    getDetailTravelRuleJurisdictionsFailed,
    createTravelRuleJurisdictionsRequest,
    createTravelRuleJurisdictionsSuccess,
    createTravelRuleJurisdictionsFailed,
    updateTravelRuleJurisdictionsRequest,
    updateTravelRuleJurisdictionsSuccess,
    updateTravelRuleJurisdictionsFailed,
    appendTravelRuleJurisdictions,
    setCanLoadMore,
    resetTravelRuleJurisdictions,
    setTravelRuleJurisdictionsParams<K extends keyof GetTravelRuleJurisdictionsRequestParamDTO>(
      state: TravelRuleJurisdictionsState,
      action: PayloadAction<{
        key: K;
        value: GetTravelRuleJurisdictionsRequestParamDTO[K];
      }>,
    ) {
      state.params[action.payload.key] = action.payload.value;
      if (action.payload.key === Params.KeyWord) {
        state.params[Params.KeyWord.toString()] = action.payload.value;
      }
    },
    setTravelRuleJurisdictionsFilterParams,
    resetTravelRuleJurisdictionsParams,
    // Action
    fetchActionRequest,
    fetchActionSuccess,
    fetchActionFailed,
    // pagination
    getTravelRuleJurisdictionsPaginationConfigRequest,
    getTravelRuleJurisdictionsPaginationConfigSuccess,
    getTravelRuleJurisdictionsPaginationConfigFailed,
  },
});
// Actions
export const TravelRuleJurisdictionsActions = TravelRuleJurisdictionsSlice.actions;
// Reducer
export const TravelRuleJurisdictionsReducer = TravelRuleJurisdictionsSlice.reducer;
// Selectors
export const selectTravelRuleJurisdictions = (state: AppState) => state.TravelRuleJurisdictions?.TravelRuleJurisdictions;
export const selectParamTravelRuleJurisdictions = (state: AppState) => state.TravelRuleJurisdictions?.params;
export const selectCanLoadMoreTravelRuleJurisdictions = (state: AppState) =>
  state.TravelRuleJurisdictions?.canLoadMore;
export const selectTravelRuleJurisdictionsDetail = (state: AppState) => state.TravelRuleJurisdictions?.TravelRuleJurisdictionsDetail;
export const selectTravelRuleJurisdictionsPagination = (state: AppState) =>
  state.TravelRuleJurisdictions?.TravelRuleJurisdictionsPaginationConfig;
