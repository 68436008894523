import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  getReportTypeRequest,
  getDetailReportType,
  createReportTypeRequest,
  updateReportTypeRequest,
  getReportTypeRequestPaginationConfig,
} from 'services/ReportType.service';
import { ReportTypeActions } from 'store/slices/ReportType/ReportType.slice';
import { handleShowMsgErr } from 'utils/common';
import {
  GetReportTypeRequestParamDTO,
  ICreateReportType,
} from 'interfaces/ReportType';
const handleGetReportTypeRequest = function* ({
  payload,
}: PayloadAction<GetReportTypeRequestParamDTO>) {
  try {
    const res = yield call(getReportTypeRequest, payload);
    const params = yield select(state => state.ReportType.params) as any;
    const listReportType = yield select(
      state => state.ReportType?.ReportType?.data?.listReportType,
    ) as any;
    if (res) {
      yield put(
        ReportTypeActions.setCanLoadMore(
          params.offset + params.limit < res.total,
        ),
      );
      if (params.offset > 0) {
        const newList: any = [...(listReportType || []), ...res.list];
        yield put(ReportTypeActions.appendReportType(newList));
      } else {
        yield put(
          ReportTypeActions.getReportTypeSuccess({
            listReportType: res.list,
            total: res.total,
          }),
        );
      }
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(ReportTypeActions.getReportTypeFailed());
  }
};
const handleGetDetailReportTypeRequest = function* ({
  payload,
}: PayloadAction<string>) {
  try {
    const res = yield call(getDetailReportType, payload);
    if (res) {
      yield put(ReportTypeActions.getDetailReportTypeSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(ReportTypeActions.getDetailReportTypeFailed());
  }
};
const handleCreateReportTypeRequest = function* ({
  payload,
}: PayloadAction<ICreateReportType>) {
  try {
    const res = yield call(createReportTypeRequest, payload);
    if (res) {
      yield put(ReportTypeActions.createReportTypeSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(ReportTypeActions.createReportTypeFailed());
  }
};
const handleUpdateReportTypeRequest = function* ({
  payload,
}: PayloadAction<ICreateReportType>) {
  try {
    const res = yield call(updateReportTypeRequest, payload);
    if (res) {
      yield put(ReportTypeActions.updateReportTypeSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(ReportTypeActions.updateReportTypeFailed());
  }
};
const handleGetReportTypePaginationConfigRequest = function* ({
  payload,
}: PayloadAction<GetReportTypeRequestParamDTO>) {
  try {
    const res = yield call(getReportTypeRequestPaginationConfig);
    if (res) {
      yield put(ReportTypeActions.getReportTypePaginationConfigSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(ReportTypeActions.getReportTypePaginationConfigFailed());
  }
};
const ReportTypeSaga = function* () {
  yield takeLatest(
    ReportTypeActions.getReportTypeRequest.type,
    handleGetReportTypeRequest,
  );
  yield takeLatest(
    ReportTypeActions.createReportTypeRequest.type,
    handleCreateReportTypeRequest,
  );
  yield takeLatest(
    ReportTypeActions.updateReportTypeRequest.type,
    handleUpdateReportTypeRequest,
  );
  yield takeLatest(
    ReportTypeActions.getReportTypePaginationConfigRequest.type,
    handleGetReportTypePaginationConfigRequest,
  );
  yield takeLatest(
    ReportTypeActions.getDetailReportTypeRequest.type,
    handleGetDetailReportTypeRequest,
  );
};
export default ReportTypeSaga;
