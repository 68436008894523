import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Params } from 'interfaces/request';
import { PER_PAGE } from 'constants/index';
import { Sort, SortDirection } from 'enum/common';
import {
  SortReportType,
  GroupByField,
  CustomerOnScreen,
} from 'enum/ReportType.enum';
import { RequestStatus } from 'interfaces/request';
import { ResponseData } from 'interfaces/response';
import {
  CategoryGroupByRequestParamsDTO,
  GetReportTypeRequestParamDTO,
  GetReportTypeResponseDTO,
  GetCategoryDTO,
  GetCompanyListDTO,
  GetCompanyListRequestParamsDTO,
  GroupByUserRequestParamsDTO,
  IReportTypeDetail,
} from 'interfaces/ReportType';
import { AppState } from '../..';
import { IRakCRUDBaseControllerConfig } from 'interfaces/dynamicFilter';
interface ReportTypeState {
  params: GetReportTypeRequestParamDTO;
  ReportType: ResponseData<GetReportTypeResponseDTO>;
  ReportTypePaginationConfig: ResponseData<IRakCRUDBaseControllerConfig>;
  canLoadMore: boolean;
  paramsCompany: GetCompanyListRequestParamsDTO;
  company: ResponseData<GetCompanyListDTO>;
  canLoadMoreCompany: boolean;
  usersByCompany: ResponseData<any>;
  paramsUsersByCompany: GroupByUserRequestParamsDTO;
  canLoadMoreUsers: boolean;
  category: ResponseData<GetCategoryDTO>;
  paramsCategory: CategoryGroupByRequestParamsDTO;
  type: ResponseData<any>;
  paramsType: CategoryGroupByRequestParamsDTO;
  action: ResponseData<any>;
  paramsAction: CategoryGroupByRequestParamsDTO;
  ReportTypeDetail: ResponseData<IReportTypeDetail>;
}
export function getDefaultReportTypeRequestParams() {
  return {
    [Params.Offset]: 0,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: SortReportType.createdAt,
    [Params.Sort]: SortDirection.DESC,
    [Params.isAllRequest]: false,
  };
}
export function getDefaultCompanyRequestParams() {}
export function getDefaultCategoryGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultTypeGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultActionGroupByRequestParams() {
  return {
    groupBy: GroupByField.TYPE,
  };
}
export function getDefaultUsersParams() {
  return {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    customerIds: '',
  };
}
const initialState: ReportTypeState = {
  params: getDefaultReportTypeRequestParams(),
  ReportType: {
    status: RequestStatus.Idle,
  },
  ReportTypePaginationConfig: {
    status: RequestStatus.Idle,
  },
  canLoadMore: true,
  paramsCompany: {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: Sort.CUSTOMER_NAME,
    [Params.Sort]: SortDirection.ASC,
    [Params.SearchOnScreen]: CustomerOnScreen.REPORTTYPE,
  },
  company: {
    status: RequestStatus.Idle,
  },
  canLoadMoreCompany: true,
  usersByCompany: {
    status: RequestStatus.Idle,
  },
  paramsUsersByCompany: getDefaultUsersParams(),
  canLoadMoreUsers: true,
  category: {
    status: RequestStatus.Idle,
  },
  paramsCategory: getDefaultCategoryGroupByRequestParams(),
  type: {
    status: RequestStatus.Idle,
  },
  paramsType: getDefaultTypeGroupByRequestParams(),
  action: {
    status: RequestStatus.Idle,
  },
  paramsAction: getDefaultActionGroupByRequestParams(),
  ReportTypeDetail: {
    status: RequestStatus.Idle,
  },
};
const getReportTypeRequest: CaseReducer<ReportTypeState, PayloadAction<any>> = (
  state: ReportTypeState,
) => {
  state.ReportType.status = RequestStatus.Loading;
};
const getReportTypeSuccess: CaseReducer<
  ReportTypeState,
  PayloadAction<GetReportTypeResponseDTO>
> = (
  state: ReportTypeState,
  { payload }: PayloadAction<GetReportTypeResponseDTO>,
) => {
  state.ReportType.status = RequestStatus.Success;
  state.ReportType.data = payload;
};
const getReportTypeFailed: CaseReducer<ReportTypeState> = (
  state: ReportTypeState,
) => {
  state.ReportType.status = RequestStatus.Failed;
};
const getDetailReportTypeRequest: CaseReducer<
  ReportTypeState,
  PayloadAction<string>
> = (state: ReportTypeState) => {
  state.ReportTypeDetail.status = RequestStatus.Loading;
};
const getDetailReportTypeSuccess: CaseReducer<
  ReportTypeState,
  PayloadAction<IReportTypeDetail>
> = (state: ReportTypeState, { payload }: PayloadAction<IReportTypeDetail>) => {
  state.ReportTypeDetail.status = RequestStatus.Success;
  state.ReportTypeDetail.data = payload;
};
const getDetailReportTypeFailed: CaseReducer<ReportTypeState> = (
  state: ReportTypeState,
) => {
  state.ReportTypeDetail.status = RequestStatus.Failed;
};
const createReportTypeRequest: CaseReducer<
  ReportTypeState,
  PayloadAction<IReportTypeDetail>
> = (state: ReportTypeState) => {
  state.ReportTypeDetail.status = RequestStatus.Loading;
};
const createReportTypeSuccess: CaseReducer<
  ReportTypeState,
  PayloadAction<IReportTypeDetail>
> = (state: ReportTypeState, { payload }: PayloadAction<IReportTypeDetail>) => {
  state.ReportTypeDetail.status = RequestStatus.Success;
  state.ReportTypeDetail.data = payload;
};
const createReportTypeFailed: CaseReducer<ReportTypeState> = (
  state: ReportTypeState,
) => {
  state.ReportTypeDetail.status = RequestStatus.Failed;
};
const updateReportTypeRequest: CaseReducer<
  ReportTypeState,
  PayloadAction<IReportTypeDetail>
> = (state: ReportTypeState) => {
  state.ReportTypeDetail.status = RequestStatus.Loading;
};
const updateReportTypeSuccess: CaseReducer<
  ReportTypeState,
  PayloadAction<IReportTypeDetail>
> = (state: ReportTypeState, { payload }: PayloadAction<IReportTypeDetail>) => {
  state.ReportTypeDetail.status = RequestStatus.Success;
  state.ReportTypeDetail.data = payload;
};
const updateReportTypeFailed: CaseReducer<ReportTypeState> = (
  state: ReportTypeState,
) => {
  state.ReportTypeDetail.status = RequestStatus.Failed;
};
const appendReportType: CaseReducer<ReportTypeState, PayloadAction<any>> = (
  state: ReportTypeState,
  { payload }: PayloadAction<any>,
) => {
  if (state.ReportType?.data?.listReportType) {
    state.ReportType.status = RequestStatus.Success;
    state.ReportType.data.listReportType = [...payload];
  }
};
const setCanLoadMore = (
  state: ReportTypeState,
  { payload }: PayloadAction<any>,
) => {
  state.canLoadMore = payload;
};
const resetReportType: CaseReducer<ReportTypeState> = (
  state: ReportTypeState,
) => {
  state.ReportType = {
    status: RequestStatus.Idle,
  };
};
const resetReportTypeParams: CaseReducer<ReportTypeState> = (
  state: ReportTypeState,
) => {
  state.params = getDefaultReportTypeRequestParams();
};
const setReportTypeFilterParams = (
  state: ReportTypeState,
  { payload }: PayloadAction<GetReportTypeRequestParamDTO>,
) => {
  state.params = payload;
};
// TODO: Action
const fetchActionRequest: CaseReducer<
  ReportTypeState,
  PayloadAction<CategoryGroupByRequestParamsDTO>
> = (state: ReportTypeState) => {
  state.action.status = RequestStatus.Loading;
};
const fetchActionSuccess: CaseReducer<ReportTypeState, PayloadAction<any>> = (
  state: ReportTypeState,
  { payload }: PayloadAction<any>,
) => {
  state.action.status = RequestStatus.Success;
  state.action.data = payload;
};
const fetchActionFailed: CaseReducer<ReportTypeState> = (
  state: ReportTypeState,
) => {
  state.action.status = RequestStatus.Failed;
};
const getReportTypePaginationConfigRequest: CaseReducer<
  ReportTypeState,
  PayloadAction<any>
> = (state: ReportTypeState) => {
  state.ReportTypePaginationConfig.status = RequestStatus.Loading;
};
const getReportTypePaginationConfigSuccess: CaseReducer<
  ReportTypeState,
  PayloadAction<IRakCRUDBaseControllerConfig>
> = (
  state: ReportTypeState,
  { payload }: PayloadAction<IRakCRUDBaseControllerConfig>,
) => {
  state.ReportTypePaginationConfig.status = RequestStatus.Success;
  state.ReportTypePaginationConfig.data = payload;
};
const getReportTypePaginationConfigFailed: CaseReducer<ReportTypeState> = (
  state: ReportTypeState,
) => {
  state.ReportTypePaginationConfig.status = RequestStatus.Failed;
};
const ReportTypeSlice = createSlice({
  name: 'ReportType',
  initialState,
  reducers: {
    getReportTypeRequest,
    getReportTypeSuccess,
    getReportTypeFailed,
    getDetailReportTypeRequest,
    getDetailReportTypeSuccess,
    getDetailReportTypeFailed,
    createReportTypeRequest,
    createReportTypeSuccess,
    createReportTypeFailed,
    updateReportTypeRequest,
    updateReportTypeSuccess,
    updateReportTypeFailed,
    appendReportType,
    setCanLoadMore,
    resetReportType,
    setReportTypeParams<K extends keyof GetReportTypeRequestParamDTO>(
      state: ReportTypeState,
      action: PayloadAction<{
        key: K;
        value: GetReportTypeRequestParamDTO[K];
      }>,
    ) {
      state.params[action.payload.key] = action.payload.value;
      if (action.payload.key === Params.KeyWord) {
        state.params[Params.KeyWord.toString()] = action.payload.value;
      }
    },
    setReportTypeFilterParams,
    resetReportTypeParams,
    // Action
    fetchActionRequest,
    fetchActionSuccess,
    fetchActionFailed,
    // pagination
    getReportTypePaginationConfigRequest,
    getReportTypePaginationConfigSuccess,
    getReportTypePaginationConfigFailed,
  },
});
// Actions
export const ReportTypeActions = ReportTypeSlice.actions;
// Reducer
export const ReportTypeReducer = ReportTypeSlice.reducer;
// Selectors
export const selectReportType = (state: AppState) =>
  state.ReportType?.ReportType;
export const selectParamReportType = (state: AppState) =>
  state.ReportType?.params;
export const selectCanLoadMoreReportType = (state: AppState) =>
  state.ReportType?.canLoadMore;
export const selectReportTypeDetail = (state: AppState) =>
  state.ReportType?.ReportTypeDetail;
export const selectReportTypePagination = (state: AppState) =>
  state.ReportType?.ReportTypePaginationConfig;
