import { PREFIX_API } from 'constants/index';
import Instance from '../app.v2/config/instance';
import {
  GetReportTypeRequestParamDTO,
  GetReportTypeResponseDTO,
  ICreateReportType,
  IReportTypeDetail,
} from 'interfaces/ReportType';
export function getReportTypeRequest(
  params?: GetReportTypeRequestParamDTO,
): Promise<GetReportTypeResponseDTO> {
  return Instance.get(`/${PREFIX_API.REPORTTYPE}`, {
    params: {
      ...params,
      where: JSON.stringify(params?.where || {}),
      order: JSON.stringify(params?.order || []),
    },
  });
}
export function getDetailReportType(
  ReportTypeId: string,
): Promise<IReportTypeDetail> {
  return Instance.get(`/${PREFIX_API.REPORTTYPE}/${ReportTypeId}`);
}
export function createReportTypeRequest(
  ReportType: ICreateReportType,
): Promise<GetReportTypeResponseDTO> {
  return Instance.post(`/${PREFIX_API.REPORTTYPE}`, ReportType);
}
export function updateReportTypeRequest(
  ReportType: ICreateReportType,
): Promise<GetReportTypeResponseDTO> {
  const payload: any = { ...ReportType };
  const id = ReportType.id;
  delete payload.id;
  return Instance.put(`/${PREFIX_API.REPORTTYPE}/${id}`, payload);
}
export function getReportTypeRequestPaginationConfig(): Promise<GetReportTypeResponseDTO> {
  return Instance.get(`/${PREFIX_API.REPORTTYPE}/pagination-config`);
}
