import { PayloadAction } from '@reduxjs/toolkit';
import ToastMessage from 'app/components/common/ToastMessage';
import { TransactionType } from 'enum/common';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  getReviewChecklistByTxnId,
  getReviewerByTxnId,
  getTransactionDetailByTxnId,
  getTransactionSourceAndDestinationData,
  getTransactionTier,
  markTransactionAsReview,
  rejectUnfreezeTransaction,
  unFreezeTransaction,
} from 'services/transaction';
import { commonActions } from 'store/slices/common';
import { transactionActions } from 'store/slices/transaction';
import { transactionsActions } from 'store/slices/transactions';
import { handleShowMsgErr } from 'utils/common';

function* fetchData(fn: Function, ...args: any[]) {
  try {
    return yield call(fn as any, ...args);
  } catch (error) {
    return {}; // Return an empty object on error
  }
}

const fetchTransactionDetail = function* ({
  payload,
}: PayloadAction<{ txId: string }>) {
  try {
    let res = yield call(getTransactionDetailByTxnId, payload.txId);

    if (res) {
      const isContractCall = res.type === TransactionType.CONTRACT_CALL;

      const [sourceData, destinationData] = yield all([
        fetchData(
          getTransactionSourceAndDestinationData,
          isContractCall ? res.newSource.externalId : res.sourceExternalId,
          isContractCall ? res.newSource.externalType : res.sourceType,
          res.nativeAssetId,
          res.sourceWorkspaceId,
        ),
        fetchData(
          getTransactionSourceAndDestinationData,
          isContractCall
            ? res.newDestination.externalId
            : res.destinationExternalId,
          isContractCall
            ? res.newDestination.externalType
            : res.destinationType,
          res.nativeAssetId,
          res.destinationWorkspaceId,
        ),
      ]);

      res = {
        ...res,
        sourceData,
        destinationData,
      };
    }

    yield put(transactionActions.getTransactionDetailByTxnIdSuccess(res));
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(transactionActions.getTransactionDetailByTxnIdFailed());
  }
};

const handleMarkTransactionAsReview = function* ({
  payload,
}: PayloadAction<{ txId: string; body: any }>) {
  try {
    const res = yield call(markTransactionAsReview, payload.txId, payload.body);
    yield put(transactionActions.markTransactionAsReviewSuccess(res));
    toast(
      <ToastMessage type="info" message="Transaction is marked as reviewed." />,
    );
    yield put(
      transactionActions.getReviewerByTxnIdRequest({ txId: payload.txId }),
    );
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(transactionActions.markTransactionAsReviewFailed());
  }
};

const handleUnFreezeTransaction = function* ({
  payload,
}: PayloadAction<{ txId: string }>) {
  try {
    const res = yield call(unFreezeTransaction, payload.txId);
    yield put(transactionActions.unFreezeTransactionSuccess(res));
    toast(
      <ToastMessage
        type="info"
        message="The request has been submitted for approval"
      />,
    );
    yield put(
      transactionActions.getTransactionDetailByTxnIdRequest({
        txId: payload.txId,
      }),
    );
    yield put(commonActions.getTotalRequestRequest());
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(transactionActions.unFreezeTransactionFailed());
  }
};

const handleRejectUnfreezeTransaction = function* ({
  payload,
}: PayloadAction<{ txId: string }>) {
  try {
    const res = yield call(rejectUnfreezeTransaction, payload);
    yield put(transactionActions.rejectUnfreezeTransactionSuccess(res));

    yield put(
      transactionsActions.setTransactionParam({
        key: 'offset',
        value: 0,
      }),
    );
    toast(
      <ToastMessage
        type="info"
        message="Your transaction has been rejected!"
      />,
    );
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(transactionActions.rejectUnfreezeTransactionFailed());
  }
};

const fetchReviewerByTxnId = function* ({
  payload,
}: PayloadAction<{ txId: string }>) {
  try {
    const res = yield call(getReviewerByTxnId, payload.txId);
    yield put(transactionActions.getReviewerByTxnIdSuccess(res));
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(transactionActions.getReviewerByTxnIdFailed());
  }
};

const fetchReviewChecklistByTxnId = function* ({
  payload,
}: PayloadAction<{ txId: string }>) {
  try {
    const res = yield call(getReviewChecklistByTxnId, payload.txId);
    yield put(transactionActions.getReviewChecklistByTxnIdSuccess(res));
  } catch (error: any) {
    yield put(transactionActions.getReviewChecklistByTxnIdSuccess({}));
  }
};

const fetchTransactionTier = function* () {
  try {
    const res = yield call(getTransactionTier);
    yield put(transactionActions.getTransactionTierSuccess(res));
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(transactionActions.getTransactionTierFailed());
  }
};

const transactionSaga = function* () {
  yield takeLatest(
    transactionActions.getTransactionDetailByTxnIdRequest.type,
    fetchTransactionDetail,
  );

  yield takeLatest(
    transactionActions.markTransactionAsReviewRequest.type,
    handleMarkTransactionAsReview,
  );

  yield takeLatest(
    transactionActions.getReviewerByTxnIdRequest.type,
    fetchReviewerByTxnId,
  );

  yield takeLatest(
    transactionActions.getReviewChecklistByTxnIdRequest.type,
    fetchReviewChecklistByTxnId,
  );

  yield takeLatest(
    transactionActions.unFreezeTransactionRequest.type,
    handleUnFreezeTransaction,
  );

  yield takeLatest(
    transactionActions.rejectUnfreezeTransactionRequest.type,
    handleRejectUnfreezeTransaction,
  );

  yield takeLatest(
    transactionActions.getTransactionTierRequest.type,
    fetchTransactionTier,
  );
};

export default transactionSaga;
