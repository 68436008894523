import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  getBatchElvenTxnRAKFBRequest,
  getDetailBatchElvenTxnRAKFB,
  createBatchElvenTxnRAKFBRequest,
  updateBatchElvenTxnRAKFBRequest,
  getBatchElvenTxnRAKFBRequestPaginationConfig,
} from 'services/BatchElvenTxnRAKFB.service';
import { BatchElvenTxnRAKFBActions } from 'store/slices/BatchElvenTxnRAKFB/BatchElvenTxnRAKFB.slice';
import { handleShowMsgErr } from 'utils/common';
import { GetBatchElvenTxnRAKFBRequestParamDTO, ICreateBatchElvenTxnRAKFB } from 'interfaces/BatchElvenTxnRAKFB';
const handleGetBatchElvenTxnRAKFBRequest = function* ({
  payload,
}: PayloadAction<GetBatchElvenTxnRAKFBRequestParamDTO>) {
  try {
    const res = yield call(getBatchElvenTxnRAKFBRequest, payload);
    const params = yield select(state => state.BatchElvenTxnRAKFB.params) as any;
    const listBatchElvenTxnRAKFB = yield select(
      state => state.BatchElvenTxnRAKFB?.BatchElvenTxnRAKFB?.data?.listBatchElvenTxnRAKFB,
    ) as any;
    if (res) {
      yield put(
        BatchElvenTxnRAKFBActions.setCanLoadMore(params.offset + params.limit < res.total),
      );
      if (params.offset > 0) {
        const newList: any = [...(listBatchElvenTxnRAKFB || []), ...res.list];
        yield put(BatchElvenTxnRAKFBActions.appendBatchElvenTxnRAKFB(newList));
      } else {
        yield put(
          BatchElvenTxnRAKFBActions.getBatchElvenTxnRAKFBSuccess({
            listBatchElvenTxnRAKFB: res.list,
            total: res.total,
          }),
        );
      }
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(BatchElvenTxnRAKFBActions.getBatchElvenTxnRAKFBFailed());
  }
};
const handleGetDetailBatchElvenTxnRAKFBRequest = function* ({
  payload,
}: PayloadAction<string>) {
  try {
    const res = yield call(getDetailBatchElvenTxnRAKFB, payload);
    if (res) {
      yield put(BatchElvenTxnRAKFBActions.getDetailBatchElvenTxnRAKFBSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(BatchElvenTxnRAKFBActions.getDetailBatchElvenTxnRAKFBFailed());
  }
};
const handleCreateBatchElvenTxnRAKFBRequest = function* ({
  payload,
}: PayloadAction<ICreateBatchElvenTxnRAKFB>) {
  try {
    const res = yield call(createBatchElvenTxnRAKFBRequest, payload);
    if (res) {
      yield put(BatchElvenTxnRAKFBActions.createBatchElvenTxnRAKFBSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(BatchElvenTxnRAKFBActions.createBatchElvenTxnRAKFBFailed());
  }
};
const handleUpdateBatchElvenTxnRAKFBRequest = function* ({
  payload,
}: PayloadAction<ICreateBatchElvenTxnRAKFB>) {
  try {
    const res = yield call(updateBatchElvenTxnRAKFBRequest, payload);
    if (res) {
      yield put(BatchElvenTxnRAKFBActions.updateBatchElvenTxnRAKFBSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(BatchElvenTxnRAKFBActions.updateBatchElvenTxnRAKFBFailed());
  }
};
const handleGetBatchElvenTxnRAKFBPaginationConfigRequest = function* ({
  payload,
}: PayloadAction<GetBatchElvenTxnRAKFBRequestParamDTO>) {
  try {
    const res = yield call(getBatchElvenTxnRAKFBRequestPaginationConfig);
    if (res) {
      yield put(BatchElvenTxnRAKFBActions.getBatchElvenTxnRAKFBPaginationConfigSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(BatchElvenTxnRAKFBActions.getBatchElvenTxnRAKFBPaginationConfigFailed());
  }
};
const BatchElvenTxnRAKFBSaga = function* () {
  yield takeLatest(BatchElvenTxnRAKFBActions.getBatchElvenTxnRAKFBRequest.type, handleGetBatchElvenTxnRAKFBRequest);
  yield takeLatest(BatchElvenTxnRAKFBActions.createBatchElvenTxnRAKFBRequest.type, handleCreateBatchElvenTxnRAKFBRequest);
  yield takeLatest(BatchElvenTxnRAKFBActions.updateBatchElvenTxnRAKFBRequest.type, handleUpdateBatchElvenTxnRAKFBRequest);
  yield takeLatest(
    BatchElvenTxnRAKFBActions.getBatchElvenTxnRAKFBPaginationConfigRequest.type,
    handleGetBatchElvenTxnRAKFBPaginationConfigRequest,
  );
  yield takeLatest(
    BatchElvenTxnRAKFBActions.getDetailBatchElvenTxnRAKFBRequest.type,
    handleGetDetailBatchElvenTxnRAKFBRequest,
  );
};
export default BatchElvenTxnRAKFBSaga;
