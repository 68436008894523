import { PREFIX_API } from 'constants/index';
import Instance from 'app.v2/config/instance';
import {
  GetBatchElvenTxnRAKFBRequestParamDTO,
  GetBatchElvenTxnRAKFBResponseDTO,
  ICreateBatchElvenTxnRAKFB,
  IBatchElvenTxnRAKFBDetail,
} from 'interfaces/BatchElvenTxnRAKFB';
export function getBatchElvenTxnRAKFBRequest(
  params?: GetBatchElvenTxnRAKFBRequestParamDTO,
): Promise<GetBatchElvenTxnRAKFBResponseDTO> {
  return Instance.get(`/${PREFIX_API.BATCHELVENTXNRAKFB}`, {
    params: {
      ...params,
      where: JSON.stringify(params?.where || {}),
      order: JSON.stringify(params?.order || []),
    },
  });
}
export function getDetailBatchElvenTxnRAKFB(
  BatchElvenTxnRAKFBId: string,
): Promise<IBatchElvenTxnRAKFBDetail> {
  return Instance.get(`/${PREFIX_API.BATCHELVENTXNRAKFB}/${ BatchElvenTxnRAKFBId }`);
}
export function createBatchElvenTxnRAKFBRequest(
  BatchElvenTxnRAKFB: ICreateBatchElvenTxnRAKFB,
): Promise<GetBatchElvenTxnRAKFBResponseDTO> {
  return Instance.post(`/${PREFIX_API.BATCHELVENTXNRAKFB}`, BatchElvenTxnRAKFB);
}
export function updateBatchElvenTxnRAKFBRequest(
  BatchElvenTxnRAKFB: ICreateBatchElvenTxnRAKFB,
): Promise<GetBatchElvenTxnRAKFBResponseDTO> {
  const payload: any = { ...BatchElvenTxnRAKFB };
  const id = BatchElvenTxnRAKFB.id;
  delete payload.id;
  return Instance.put(`/${PREFIX_API.BATCHELVENTXNRAKFB}/${id}`, payload);
}
export function getBatchElvenTxnRAKFBRequestPaginationConfig(): Promise<GetBatchElvenTxnRAKFBResponseDTO> {
  return Instance.get(`/${PREFIX_API.BATCHELVENTXNRAKFB}/pagination-config`);
}
