import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  getTravelRuleJurisdictionsRequest,
  getDetailTravelRuleJurisdictions,
  createTravelRuleJurisdictionsRequest,
  updateTravelRuleJurisdictionsRequest,
  getTravelRuleJurisdictionsRequestPaginationConfig,
} from 'services/TravelRuleJurisdictions.service';
import { TravelRuleJurisdictionsActions } from 'store/slices/TravelRuleJurisdictions/TravelRuleJurisdictions.slice';
import { handleShowMsgErr } from 'utils/common';
import {
  GetTravelRuleJurisdictionsRequestParamDTO,
  ICreateTravelRuleJurisdictions,
} from 'interfaces/TravelRuleJurisdictions';
const handleGetTravelRuleJurisdictionsRequest = function* ({
  payload,
}: PayloadAction<GetTravelRuleJurisdictionsRequestParamDTO>) {
  try {
    const res = yield call(getTravelRuleJurisdictionsRequest, payload);
    const params = yield select(
      state => state.TravelRuleJurisdictions.params,
    ) as any;
    const listTravelRuleJurisdictions = yield select(
      state =>
        state.TravelRuleJurisdictions?.TravelRuleJurisdictions?.data
          ?.listTravelRuleJurisdictions,
    ) as any;
    if (res) {
      yield put(
        TravelRuleJurisdictionsActions.setCanLoadMore(
          params.offset + params.limit < res.total,
        ),
      );
      if (params.offset > 0) {
        const newList: any = [
          ...(listTravelRuleJurisdictions || []),
          ...res.list,
        ];
        yield put(
          TravelRuleJurisdictionsActions.appendTravelRuleJurisdictions(newList),
        );
      } else {
        yield put(
          TravelRuleJurisdictionsActions.getTravelRuleJurisdictionsSuccess({
            listTravelRuleJurisdictions: res.list,
            total: res.total,
          }),
        );
      }
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(
      TravelRuleJurisdictionsActions.getTravelRuleJurisdictionsFailed(),
    );
  }
};
const handleGetDetailTravelRuleJurisdictionsRequest = function* ({
  payload,
}: PayloadAction<string>) {
  try {
    const res = yield call(getDetailTravelRuleJurisdictions, payload);
    if (res) {
      yield put(
        TravelRuleJurisdictionsActions.getDetailTravelRuleJurisdictionsSuccess(
          res,
        ),
      );
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(
      TravelRuleJurisdictionsActions.getDetailTravelRuleJurisdictionsFailed(),
    );
  }
};
const handleCreateTravelRuleJurisdictionsRequest = function* ({
  payload,
}: PayloadAction<ICreateTravelRuleJurisdictions>) {
  try {
    const res = yield call(createTravelRuleJurisdictionsRequest, payload);
    if (res) {
      yield put(
        TravelRuleJurisdictionsActions.createTravelRuleJurisdictionsSuccess(
          res,
        ),
      );
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(
      TravelRuleJurisdictionsActions.createTravelRuleJurisdictionsFailed(),
    );
  }
};
const handleUpdateTravelRuleJurisdictionsRequest = function* ({
  payload,
}: PayloadAction<ICreateTravelRuleJurisdictions>) {
  try {
    const res = yield call(updateTravelRuleJurisdictionsRequest, payload);
    if (res) {
      yield put(
        TravelRuleJurisdictionsActions.updateTravelRuleJurisdictionsSuccess(
          res,
        ),
      );
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(
      TravelRuleJurisdictionsActions.updateTravelRuleJurisdictionsFailed(),
    );
  }
};
const handleGetTravelRuleJurisdictionsPaginationConfigRequest = function* ({
  payload,
}: PayloadAction<GetTravelRuleJurisdictionsRequestParamDTO>) {
  try {
    const res = yield call(getTravelRuleJurisdictionsRequestPaginationConfig);
    if (res) {
      yield put(
        TravelRuleJurisdictionsActions.getTravelRuleJurisdictionsPaginationConfigSuccess(
          res,
        ),
      );
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(
      TravelRuleJurisdictionsActions.getTravelRuleJurisdictionsPaginationConfigFailed(),
    );
  }
};
const TravelRuleJurisdictionsSaga = function* () {
  yield takeLatest(
    TravelRuleJurisdictionsActions.getTravelRuleJurisdictionsRequest.type,
    handleGetTravelRuleJurisdictionsRequest,
  );
  yield takeLatest(
    TravelRuleJurisdictionsActions.createTravelRuleJurisdictionsRequest.type,
    handleCreateTravelRuleJurisdictionsRequest,
  );
  yield takeLatest(
    TravelRuleJurisdictionsActions.updateTravelRuleJurisdictionsRequest.type,
    handleUpdateTravelRuleJurisdictionsRequest,
  );
  yield takeLatest(
    TravelRuleJurisdictionsActions
      .getTravelRuleJurisdictionsPaginationConfigRequest.type,
    handleGetTravelRuleJurisdictionsPaginationConfigRequest,
  );
  yield takeLatest(
    TravelRuleJurisdictionsActions.getDetailTravelRuleJurisdictionsRequest.type,
    handleGetDetailTravelRuleJurisdictionsRequest,
  );
};
export default TravelRuleJurisdictionsSaga;
